import { createSlice } from '@reduxjs/toolkit';
const  initialState = {
    value:'Grati Diciembre 2021',
}
export const promotionStore = createSlice({
    name:"Promotion",
    initialState,
    reducers:{
        changePromotion:(state,action)=>{
            console.log("change name",action.payload)
            state.value = action.payload
        }
    }

})
export const { changePromotion } = promotionStore.actions
export default promotionStore.reducer