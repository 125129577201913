import React, { Component } from 'react'
import MediaQuery from 'react-responsive'
import Slider from "react-slick";

import './banner.scss'
export default class Banner extends Component {
    constructor(props){
        super(props)
        this.afterChange = this.afterChange.bind(this)
        this.state = {
            card:[
                {
                    project: "midgo",
                    district:"Lince",
                    address:"Av. Arequipa 1890",
                    imgBrand: "brand-midgo",
                    imgProject: require("../../assets/images/iconos/render_midgo.jpg"),
                    fase: "En preventa",
                    valuation: "",
                    dorms: "1 y 2 Dorms",
                    dormsShow: "1 y 2 Dorms",
                    tendency: "Tendencia",
                    all: "all",
                    link: "https://midgo.cosapiinmobiliaria.com.pe/",
                    index: 1,
                    button:"Registrate aquí",
                },
                {
                    project: "momen",
                    district:"Breña",
                    address:"Av. Aguarico 1251",
                    imgBrand: "brand-momen",
                    imgProject: require("../../assets/images/iconos/render-momen-web.jpg"),
                    fase: "Lanzamiento",
                    valuation: "",
                    dorms: "2 y 3 Dorms",
                    dormsShow: "2 y 3 Dorms",
                    tendency: "Tendencia",
                    all: "all",
                    link: "https://momen.cosapiinmobiliaria.com.pe/",
                    index: 1,
                    button:"Registrate aquí",
                },
                {
                    project: "ativa",
                    district:"Surco",
                    address:"Av. El derby 160",
                    imgBrand: "brand-ativa",
                    imgProject: require("../../assets/images/iconos/ativa.png"),
                    fase: "En preventa",
                    valuation: "",
                    dorms: "1, 2 y 3 Dorms",
                    dormsShow: "1, 2 y 3 Dorms",
                    tendency: "Tendencia",
                    all: "all",
                    link: "https://ativa.cosapiinmobiliaria.com.pe/",
                    index: 1,
                    button:"Registrate aquí",
                },
                {
                    project: "nesta",
                    district:"Jesús María",
                    address:"Av. Salaverry 475",
                    imgBrand: "brand-nesta",
                    imgProject: require("../../assets/images/Inicio/render/render-nesta.jpg"),
                    fase: "Lanzamiento",
                    valuation: "Éxito en ventas",
                    dorms: "1, 2 y 3 Dorms",
                    dormsShow: "1, 2 y 3 Dorms",
                    views: {desde:"715",hasta:"1524"},
                    tendency: "Tendencia",
                    all: "all",
                    link: "https://nesta.com.pe/",
                    index: 3,
                    button:"Registrate aquí",
                },
            ],
            settings:{
                dots: false,
                arrows:false,
                infinite: false,
                centerMode: true,
                speed: 500,
                slidesToShow: 1,
                slidesToScroll: 1,
                variableWidth: true,
                afterChange:this.afterChange
            },
        }
    }
    afterChange(e){
        this.setState({activeSlider:e})
    }
    render() {
        return (
            <section className="banner">
                <img className='background-point-left' src={require('../../assets/images/Inicio/iconos/background-points.png')} ></img>
                <img className='background-point-right' src={require('../../assets/images/Inicio/iconos/background-lines-right.png')} ></img>
                
               <div className="content-banner">
                    
                    <div className="title-banner">
                        <p className="paragraph paragraph-1 gotham">
                            ¿Estás interesado en <span className="Third bold">otros proyectos? </span> 
                        </p>
                        <p className="paragraph paragraph-2 gotham">
                            Conoce todos  <span className="bold c-black">los que tenemos</span> 
                        </p>
                    </div>

                    <div className="content-build">
                        <MediaQuery query='(min-width:500px)'>
                            <div className="content-place">
                                <div className="item"><img className="img-fluid" src={require("../../assets/images/iconos/render-midgo.png")}></img><span className="place ultra c-black">Lince</span></div>
                                <div className="item"><img className="img-fluid" src={require("../../assets/images/iconos/render-momen.png")}></img><span className="place ultra c-black">Breña</span></div>
                                <div className="item"><img className="img-fluid" src={require("../../assets/images/iconos/render_ativa.png")}></img><span className="place ultra c-black">Surco</span></div>
                                <div className="item"><img className="img-fluid" src={require("../../assets/images/new-section/nesta-v2.png")}></img><span className="place ultra c-black">Jesús María</span></div>
                            </div>
                        </MediaQuery>
                        <MediaQuery query='(max-width:499px)'>
                        <div className='content-info-slide projects'>
                            <Slider {...this.state.settings} ref={slider => (this.slider = slider)}>
                                {
                                    this.state.card.map((e,index)=>{
                                        return(
                                            <div key={"card-item"} className={`card-item ${e.project}`}>
                                                <div className={`item-brand ${e.imgBrand}`}></div>
                                                <div className="content-render">
                                                    <img className="render" src={e.imgProject}></img>
                                                    <div className={`fase-content`}>
                                                        <div className={`fase b-${e.project}`}>
                                                            {e.fase}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="background-shadow">
                                                    <div className="details">
                                                        <div className="content-detail">
                                                            <div className={`detail-brand ${e.imgBrand}`}></div>
                                                            <a className="button" href={e.link}>
                                                                <div className={`add b-${e.project}`}></div>
                                                            </a>
                                                        </div>
                                                        <div className="content-detail">
                                                            <div className={`district b-${e.project}`}>{e.district}</div>
                                                            <div className={`dorms`}>
                                                                {e.dormsShow}
                                                            </div>
                                                        </div>
                                                        <div className="content-detail">
                                                            <div className={`content-place`}>
                                                                <span className="title-place" dangerouslySetInnerHTML={{__html: `${e.address}, ${e.district}`}}></span>
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </Slider>
                        </div>
                        </MediaQuery>
                        <div className="content-link">
                            <p className="paragraph paragraph-3 text-center  gotham">
                                Ingresa a  
                            </p>
                            <a href="https://www.eldepadetussueños.pe" target="__blank" className="button btn-submit ultra">
                                www.eldepadetussueños.pe
                            </a>
                            <a href='https://www.cosapiinmobiliaria.com.pe/' target='_blank'>
                                <img className="brand" src={require("../../assets/images/new-section/brand-cosapi.png")}></img>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
