import * as Yup from 'yup';

export const BaseInterestedConstraints = {
    fname: Yup.string().matches(/([A-Za-z\d])+/g, 'El nombre no debe contener números')
        .required(),
    lname: Yup.string().matches(/([A-Za-z\d])+/g, 'El nombre no debe contener números')
        .required(),
    email: Yup.string()
        .email()
        .required(),
    phone: Yup.string()
        .matches(/[\+?\-?0-9]{7,}/)
        .required(),
}

export const BaseValidatonSchema = (values) => Yup.object()
    .shape({
        ...BaseInterestedConstraints,
        terms: Yup.bool()
            .oneOf([true]).required()
    })

export const ComplaintsBookValidatonSchema = (values) => Yup.object()
    .shape({
        ...BaseInterestedConstraints,
        mlname: Yup.string()
            .required(),
        idType: Yup.string()
            .required(),
        idNumber: Yup.string()
            .required(),
        region: Yup.string()
            .required(),
        province: Yup.string()
            .required(),
        district: Yup.string()
            .required(),
        address: Yup.string()
            .required(),
        typeOfGood: Yup.string()
            .required(),
        orderNumber: Yup.string()
            .required(),
        amount: Yup.string()
            .required(),
        goodDescription: Yup.string()
            .required(),
        complaintType: Yup.string()
            .required(),
        complaintDetail: Yup.string()
            .required(),
        consumerPetiton: Yup.string()
            .required(),
        sellerAction: Yup.string()
            .required(),
        terms: Yup.bool()
            .oneOf([true]).required()
    })

export const ContactValidatonSchema = (values) => Yup.object()
    .shape({
        ...BaseInterestedConstraints,

        // date_preference: Yup.date()
        //     .min(new Date(Date.now() - 86400000))
        //     .required(),
        // time_of_day: Yup.string()
        //     .required(),
        // contact_medium: Yup.string()
        //     .required(),
        isEvent: Yup.boolean(),
        motive: Yup.string()
            .when("isEvent", {is: false, then: Yup.string().required()}),
        rooms_amount: Yup.string()
            .when("isEvent", {is: false, then: Yup.string().required()}),
        reservation_date: Yup.string()
            .when("isEvent", {is: true, then: Yup.string().required()}),
        reservation_time: Yup.string()
            .when("isEvent", {is: true, then: Yup.string().required()}),
        terms: Yup.bool()
            .oneOf([true]).required()
    })