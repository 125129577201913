import React, { Component } from 'react'
import "./contacto.scss"
import Navbar from '../../navbar/navbar'
import $ from 'jquery';
import Formulario from '../../paginas/inicio/formulario/formulario';
export default class Contactoareascomunes extends Component {
    constructor(){
        super()
    }
    componentDidMount(){
        
    }
    changeDate(){
        $(".date").attr("type","date")
    }
    render() {
        return (
            <div className="Contacto" id="contacto">
                <div className="relative">
                    <img className="contacto-background" src={require("../../../assets/images/contacto/contacto-background.png")}></img>
                    <Formulario></Formulario>
                </div>
            </div>
        )
    }
}
