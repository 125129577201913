import React, { Component } from 'react'
import './formulario.scss'
import $ from 'jquery'
import Swal from "sweetalert2"
import { LeadService } from 'components/services/LeadService'
import {  FormContainer, checkableBoolProps, setInputProps } from 'components/common/forms/Form'
import { ContactValidatonSchema } from 'components/common/forms/constraints/ValidatonSchemas'
import { connect } from 'react-redux'
import { activeNotice } from 'data/notice'
class Formulario extends Component {
    constructor(props) {
        super(props)
        this._leadService = new LeadService();
        this.submitForm = this.submitForm.bind(this)
    }
    initialValues = {
        fname: "",
        lname: "",
        email: "",
        phone: "",
        rooms_amount: "",
        reservation_date: "",
        reservation_time: "",
        // date_preference: "",
        // time_of_day: "",
        // contact_medium: "",
        motive: "",
        terms: false,
        contact_form: 'main_form'
    }
    submitForm(values, { setSubmitting, resetForm }) {

        let sellers = [
            // {id: 105, name: "Raquel", gender: "F", phone: "963764661"},
            // {id: 106, name: "Diago", gender: "M", phone: "945209211"},
        ]

        // values.seller_id = sellers[Math.floor(Math.random() * sellers.length)].id
        values.promotion = this.props?.event?.data?.name
        this._leadService.send(values).then(r=>{
            // this.props.activeNotice()
            window.location = '/gracias'
        }).catch(e=>Swal.fire({
            title: '',
            text: '¡Error! inténtelo más tarde',
            icon: 'error',
            confirmButtonText: 'Aceptar',
            confirmButtonColor: 'red'
        })).finally(r=>{
            // document.querySelector("form").reset()
            // resetForm(this.initialValues)
            setSubmitting(false)
        })
    }
    

    componentDidMount(){
        console.log('notice',this.props)
    }
    render() {
        const {data: promo} = this.props.event
        return (
            <FormContainer
            initialValues={{...this.initialValues, ...promo?.fields?.reservation_date && {isEvent:true}}}
            validationSchema={ContactValidatonSchema}
            onSubmit={this.submitForm}>
            {form => {const {handleSubmit} = form;
            return(
                <form className="formulario" onSubmit={handleSubmit}>
                    <h3 className="title">
                        <span className="d-block title1"><p className='t1 gotham'>¡Estás a un paso de</p><p className='t2 ultra'>cumplir tus sueños!</p></span>
                        <span className="d-block title2">Agenda una asesoría especializada completando el siguiente formulario</span>
                    </h3>
                    <div className="Content-inputs">
                        <input type="text" placeholder="Nombre*" {...setInputProps("fname", "inputs", form)}></input>
                        <input type="text" placeholder="Apellidos*" {...setInputProps("lname", "inputs", form)}></input>
                        <input type="number" placeholder="Teléfono / Celular*" {...setInputProps("phone", "inputs", form)}></input>
                        <input type="email" placeholder="Correo electrónico*"{...setInputProps("email", "inputs", form)}></input>
                        {promo?.fields?.reservation_date &&
                        <select {...setInputProps("reservation_date", "inputs", form)}>
                            {promo.fields.reservation_date.map(([value, text]) =>
                            <option value={value}>{text}</option>
                            )}
                        </select>}
                        {promo?.fields?.reservation_time &&
                        <select {...setInputProps("reservation_time", "inputs", form)}>
                            {promo.fields.reservation_time.map(([value, text]) =>
                            <option value={value}>{text}</option>
                            )}
                        </select>}
                        {promo?.fields?.rooms_amount !== false && 
                        <select {...setInputProps("rooms_amount", "inputs", form)}>
                            <option selected value="">N° de dormitorios</option>
                            <option>1 dormitorio</option>
                            <option>2 dormitorios</option>
                            <option>3 dormitorios</option>
                        </select>}
                        {promo?.fields?.motive !== false && 
                        <select {...setInputProps("motive", "inputs", form)}>
                            <option selected value="">Motivo</option>
                            <option>Primera vivienda</option>
                            <option>Inversión</option>
                        </select>}
                    </div>
                    <div className="terminos flex-row">
                        <input type="checkbox" id="checkbox2" {...checkableBoolProps("terms", "", form)}></input>
                        <label htmlFor="checkbox2" className="show-selector"></label>
                        <p className="condiciones">He leído y acepto la <a href={require("../../../../assets/files/Politica-de-privacidad.pdf")} target="_blank" className="Primary">política de datos personales.</a></p>
                    </div>
                    <div className="btn-contacto">
                        <button type="submit" className="btn-submit" disabled={form.isSubmitting}>{form.isSubmitting ? "Enviando" : "Enviar"}</button>
                    </div>
                </form>)}}
            </FormContainer>
        )
    }
}

const MapStateProps = (state)=>{
    return(
        {
            value:state.noticeStore,
            event:state.counterStore
        }
    )
}
export default connect(MapStateProps,{activeNotice})(Formulario)