import React from "react"
import MediaQuery from "react-responsive";
import './creamomentos.scss';
import OwlCarousel from "react-owl-carousel2"
export default function CreaMomentos(){
    const [items, setItems] = React.useState([
        {
            video:require("../../../assets/videos/muvin_pareja.mp4"),
            title:"Un viernes de cita",
            miniature:require("../../../assets/videos/miniatura-pareja.png"),
            paragraph:"Enamórate de tu departamento todos los días y crea momentos únicos sin salir de Muvin.",
            time:"1:24"
        },
        {
            video:require("../../../assets/videos/muvin-niños.mp4"),
            title:"Si puedes soñarlo puedes hacerlo realidad",
            miniature:require("../../../assets/videos/miniatura-juegos.png"),
            paragraph:"¡La diversión no tiene fin! Tus pequeños amarán todos los espacios diseñados para sus aventuras.",
            time:"0:52"
        },
        {
            video:require("../../../assets/videos/muvin-amigas.mp4"),
            title:"Tarde de chicas en el Beauty Room",
            miniature:require("../../../assets/videos/miniatura-amigas.png"),
            paragraph:"¡Una reu con tu mejor amiga en tu nuevo depa es una gran idea!",
            time:"1:05"
        },

    ])
    const pauseVideo = (id) => {
        let allVideos = document.querySelectorAll("video");
        allVideos.forEach((video,index) => {
            let miniature = document.querySelector(`#miniature-${index}`).classList.remove('hidden')
            video.pause();
            video.currentTime = 0;
        });
    }
    const showVideo = (id) => {
        try {
            pauseVideo();
            let video = document.querySelector(`#video-${id}`)
            let miniature = document.querySelector(`#miniature-${id}`).classList.add('hidden')
            video.play();
        } catch (error) {
            console.log(error)
        }
    }
    const options = {
        rewind: false,
        loop: false,
        nav: false,
        center: false,
        autoWidth: true,
        speed: 1000,
        smartSpeed: 300,
        dots: true,
        responsive: {
            0: {
                items: 1,
                margin: 20,
            },
            500: { 
                center: false
            }
        }
    }
    return(
        <div className='moments'>
            <img className='left-top' src={require('../../../assets/images/iconos/left-top.svg').default} ></img>
            <img className='right-bottom' src={require('../../../assets/images/iconos/right-bottom.svg').default} ></img>
            <img className='point-bottom-left' src={require('../../../assets/images/iconos/point-bottom-left.svg').default} ></img>
            <img className='point-top-right' src={require('../../../assets/images/iconos/point-top-right.svg').default} ></img>
           
            <div className='container'>
                <img className="title" src={require("../../../assets/images/iconos/momentos-muvin.svg").default}></img>
                <div className='content-videos'>
                <MediaQuery query="(min-width: 500px)">
                {items.map((item, index) => {
                        return(
                            <div className='item' key={index}>
                                <div className="content-video">
                                    <img className='miniature' src={item.miniature} id={`miniature-`+index}></img>
                                    <img className='play' src={require('../../../assets/videos/Play.svg').default} onClick={()=>{showVideo(index)}}></img>
                                    <video controls id={"video-"+index}>
                                        <source src={item.video}/>
                                    </video>
                                </div>
                                <div className="info">
                                    <span className='title-video GothamMedium'>{item.title}</span>
                                    <p className='paragraph'>{item.paragraph}</p>
                                    <span className='time'>{item.time}</span>
                                </div>
                            </div>
                            )
                    })}
                </MediaQuery>
                <MediaQuery query="(max-width: 499px)">
                    <OwlCarousel options={options} className="owl-slider">
                    {items.map((item, index) => {
                            return(
                                <div className='item' key={index}>
                                    <div className="content-video">
                                        <img className='miniature' src={item.miniature} id={`miniature-`+index}></img>
                                        <img className='play' src={require('../../../assets/videos/Play.svg').default} onClick={()=>{showVideo(index)}}></img>
                                        <video controls id={"video-"+index}>
                                            <source src={item.video}/>
                                        </video>
                                    </div>
                                    <div className="info">
                                        <span className='title-video GothamMedium'>{item.title}</span>
                                        <p className='paragraph'>{item.paragraph}</p>
                                        <span className='time'>{item.time}</span>
                                    </div>
                                </div>
                                )
                        })}
                    </OwlCarousel>
                </MediaQuery>
                </div>
            </div>
        </div>
    )
}