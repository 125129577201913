import React, { Component } from 'react'
import "./virtual.scss"
import $ from 'jquery'
import MediaQuery from 'react-responsive';

export default class Virtual extends Component {
    componentDidMount(){
        
    }
    render() {
        return (
            <section className="recorrido-virtual">
                
                <div className="flex-row content-frames">
                    <div className="iframe">
                        <div className="button ultra">2 dormitorios</div>
                        <a href="https://muvin.pe/tours/2DORMS/" target="_blank" className="frame">
                            <img src={require("../../../assets/images/recorrido-virtual/img-1.png")}></img>
                        </a>
                    </div>
                    <div className="iframe">
                        <div className="button ultra">3 dormitorios</div>
                        <a href="https://muvin.pe/tours/3DORMS/" target="_blank" className="frame">
                            <img src={require("../../../assets/images/recorrido-virtual/img-2.png")}></img>
                        </a>
                    </div>
                </div>
            </section>
        )
    }
}
