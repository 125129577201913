import React, { Component } from 'react';
import './notice.scss';
import Icon from 'components/icon/Icon';
import { connect } from 'react-redux';
import { closeNotice } from 'data/notice';
const check = require('../../assets/lottie/check.json')

class Notice extends Component {
    constructor(props){
        super(props)
        this.state = {
            active:true,
            options:{

            }
        }
    }
    hideLoading=()=>{
        
    }
    close=(e)=>{
        if(e.target.dataset.type == 'notice'){
            this.props.closeNotice();
        }
    }
    render() {
        return (
            <div className={`notice ${this.props.value.value ? 'active':''}`} data-type='notice' onClick={(e)=>{this.close(e)}}>
                <div className='content-notice'>
                    <div className='svg-lottie'>
                        {
                            this.props.value.value ? 
                            <Icon icon={check} ref={element => {this.logo = element;}} event={this.hideLoading} name={'icon-check'}></Icon>                        
                            : null 
                        }
                    </div>
                    <div className='message'>
                        Muchas gracias, un asesor te contactará pronto
                    </div>
                    <div className='btn c-white b-third' data-type='notice'>
                        Aceptar
                    </div>
                </div>
            </div>
        )
    }
}

const MapStateProps = (state)=>{
    return(
        {
            value:state.noticeStore
        }
    )
}

export default connect(MapStateProps,{closeNotice})(Notice)