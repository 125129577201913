import { configureStore } from '@reduxjs/toolkit'
import { getDefaultMiddleware } from '@reduxjs/toolkit';
import promotionStore from './promotionStore';
import sliderStore from './sliderStore';
import noticeStore from './notice';
import counterStore from './counterStore';
const customizedMiddleware = getDefaultMiddleware({
    serializableCheck: false
  })

export default configureStore({
    reducer: {
        promotionStore,
        sliderStore,
        noticeStore,
        counterStore
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
})

