import React, { Component } from 'react'
import SectionContact from 'components/paginas/inicio/section-contacto/contacto';
import Formulario from '../inicio/formulario/formulario';
import './contactanos.scss'
export default class Contactanos extends Component {
    render() {
        return (
            <section className='contactanos SectionContact'>
                <div className='container'>
                    <div className='img'>
                        <img className='' src={require('../../../assets/images/contacto/cambios-muvin-contacto.png')} ></img>
                    </div>
                    <div className='content-form'>
                        <Formulario></Formulario>
                    </div>
                </div>
            </section>
        )
    }
}
