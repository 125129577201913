import * as Yup from 'yup';
export const baseSchema = (values) =>
	Yup.object().shape({
		fname: Yup.string().matches(/([A-Za-z\d])+/g, 'El nombre no debe contener números').required(),
		lname: Yup.string().matches(/([A-Za-z\d])+/g, 'El nombre no debe contener números').required(),
		phone: Yup.number()
			.integer()
			.test('len', (val) => val.toString().length >= 9 && val.toString().length <= 9)
			.required(),
		email: Yup.string().email().required(),
		terms: Yup.bool().oneOf([true]).required(),
	});