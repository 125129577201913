import React, { Component , createRef, useState } from 'react'
import { Link } from "react-router-dom";
import './navbar.scss'
import MediaQuery from 'react-responsive';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import Icon  from '../icon/Icon';
const gift = require('../../assets/lottie/gift.json')
class Navbar extends Component {
    constructor(props){
        super(props)
       
        this.activePosition = this.activePosition.bind(this)
        this.close = this.close.bind(this)
        this.toggle = this.toggle.bind(this)
        this.toggleLink = this.toggleLink.bind(this)
        this.offset = this.offset.bind(this)
        this.moveTo =  this.moveTo.bind(this)
        this.state = {
            expanded:"panel5",
            activeLine:1,
            activeNav:false,
            closeMore:false,
            toggle:false,
          
        }
        this.nav = React.createRef()
        
    }
    componentDidMount(){
        if(window.location.pathname.includes("/inicio")){
            this.setState({activeLine:1})
        }
        if(window.location.pathname.includes("/departamentos")){
            this.setState({activeLine:2})
        }
        if(window.location.pathname == "/Promociones"){
            this.setState({activeLine:3})
        }
        if(window.location.pathname == "/recorrido-virtual"){
            this.setState({activeLine:4})
        }
        if(window.location.pathname.includes("/aliados")){
            this.setState({activeLine:5})
        }
        if(window.location.pathname.includes("/contactanos")){
            this.setState({activeLine:7})
        }
        if(window.location.pathname.includes("/avance-de-obra")){
            this.setState({activeLine:8})
        }
        if(window.location.pathname.includes("/crea-momentos")){
            this.setState({activeLine:9})
        }
    }
    activePosition(e,position){
        console.log(position)
        this.setState({activeLine:position})
        this.toggleLink()
        if(window.location.search.includes("proyectos")){
            this.moveTo("#inicio-page","#all-proyectos")
        }   
    }
    close(e){
        this.setState({closeMore:true})
    }
    toggleLink(e){
        this.setState({toggle:!this.state.toggle})
    }
    toggle(e){
        this.setState({toggle:!this.state.toggle})
        // this.state.toggle == false ? document.querySelector("html").style="overflow-y: hidden;" : document.querySelector("html").style="overflow-y: scroll;" 
    }
    moveTo(elementFrom,elementTo){
        let navHeight = document.querySelector("#nav").clientHeight
        if(document.querySelector(elementTo)){
            window.scrollTo(this.offset(elementFrom).top,this.offset(elementTo).top-(navHeight-50))
        }
    }
    offset(e){
        let element = document.querySelector(e)
        if(element){
            try { 
                let top = element.getBoundingClientRect().top + window.scrollY 
                let left = element.getBoundingClientRect().left
                return {top:top,left: left}
            }catch(err){
                return "dosnt exist"
            }
        }else{
            console.log("doesn't exist")
        }
    }
   
    render() {
        return (
            <nav ref={this.nav} id="nav" className={`navbar ${this.state.activeNav ? "active" : ""}`}>
                <div className={`more b-primary ${this.state.closeMore ? "close":""}`}>
                    <div className="container">
                        <span className="sub-title sub-title-1 c-white"><img className='' src={require('../../assets/images/iconos/cosapi-brand.png')} ></img></span>
                        <div className="barra"></div>
                        <span className="sub-title c-white">Conoce todos nuestros proyectos  </span>
                        <a href="https://www.cosapiinmobiliaria.com.pe/" className="b-white btn-know">Aquí</a>
                        <div className="button" onClick={(e)=>{this.close()}}>
                            <div className="add"></div>
                        </div>
                    </div>
                </div>
                <div className="container content-nav">
                    <a href="/" className={`brand ${this.state.toggle == true ? "active" : ""}`}>
                        <img className="logo-white logo-1" src={require("../../assets/images/muvin_logo.png")}></img>
                        <img className="logo-white logo-2" src={require("../../assets/images/Inicio/iconos/logo-muvin-white.png")}></img>
                    </a>
                    <MediaQuery query="(min-width: 1200px)">
                        <OptionsNav options={this.state} position={this.activePosition} ></OptionsNav>
                    </MediaQuery>
                    <MediaQuery query="(max-width: 1200px)">
                        <div className={`toggle ${this.state.toggle == true ? "active" : ""}`} onClick={(e)=>{this.toggle(e)}}>
                            <div className="line line-1"></div>
                            <div className="line line-2"></div>
                        </div>
                    </MediaQuery>
                </div>
                <MediaQuery query="(max-width: 1200px)">
                    <OptionsNav options={this.state} position={this.activePosition} moveTo={this.moveTo}></OptionsNav>
                </MediaQuery>
            </nav>
        )
    }
}

class OptionNav extends Component{
    constructor(props){
        super(props)
        this.hideLoading = this.hideLoading.bind(this)
        this.state = {
            expanded:"",
            value:"",
            active:false,
            hide:false,
        }
        // console.log('this',this.props.options.activeLine)
    }
    handleChange = (panel) => (event, newExpanded) => {
        this.setState({expanded: newExpanded ? panel : false})
    };
    hideLoading(){
        this.setState({hide:true})
    }
    render(){
        return(
        <div className={`container-link ${this.props.options.toggle ? "active" : ""}`}>
        <div className="links">
            <div className="center">
                <a href="/departamentos" id="Departamentos" className={`link ${this.props.options.activeLine == 2 ? "active" : ""}`}
                    onClick={(e)=>{this.props.position(e,2)}}>
                    <div className="content-text cart">
                        <span className="text-link cart">Departamentos</span>
                        <span className="barra-line"></span>
                    </div>
                </a>
                <a href="/Promociones" id="Promociones" className={`link ${this.props.options.activeLine == 3 ? "active" : ""}`}
                    onClick={(e)=>{this.props.position(e,3)}}>
                    <div className="content-text cart">
                        <span className="text-link cart">Promociones <Icon icon={gift} ref={element => {this.logo = element;}} event={this.hideLoading}/> </span>
                        <span className="barra-line"></span>
                    </div>
                </a>
                <a href="/recorrido-virtual" className={`link ${this.props.options.activeLine == 4 ? "active" : ""}`}
                    onClick={(e)=>{this.props.position(e,4)}}
                    >
                    <div className="content-text cart">
                        <span className="text-link cart">Recorrido virtual</span>
                        <span className="barra-line"></span>
                    </div>
                </a>
                <a href="/aliados" className={`link ${this.props.options.activeLine == 5 ? "active" : ""}`}
                    onClick={(e)=>{this.props.position(e,5)}}
                    >
                    <div className="content-text cart">
                        <span className="text-link cart">Aliados</span>
                        <span className="barra-line"></span>
                    </div>
                </a>
                <a id="contactanos" href="/contactanos" className={`link ${this.props.options.activeLine == 7 ? "active" : ""}`}
                    onClick={(e)=>{this.props.position(e,7)}}
                    >
                    <div className="content-text cart">
                        <span className="text-link cart">Contáctanos</span>
                        <span className="barra-line"></span>
                    </div>
                </a>
                <a id="contactanos" href="avance-de-obra" className={`link ${this.props.options.activeLine == 8 ? "active" : ""}`}
                    onClick={(e)=>{this.props.position(e,8)}}
                    >
                    <div className="content-text cart">
                        <span className="text-link cart">Avance de obra</span>
                        <span className="barra-line"></span>
                    </div>
                </a>
                <a id="contactanos" href="/crea-momentos" className={`link ${this.props.options.activeLine == 9 ? "active" : ""}`}
                    onClick={(e)=>{this.props.position(e,9)}}
                    >
                    <div className="content-text cart">
                        <span className="text-link cart">Crea momentos</span>
                        <span className="barra-line"></span>
                    </div>
                </a>
                <div className='content-link-movil'>
                    <div className={`link`}>
                        {/* <div className="content-text cart">
                            <span className="text-link cart"></span>
                        </div> */}
                        <div>
                            <a target="__blank" href="tel:+51 966 874 541" class="contact icon-1 flex-row white" id="icon-1">
                                <i></i>+51 966 874 541
                            </a>
                            
                            <a class="contact icon-3 flex-row white" id="icon-3" target="__blank" href="mailto:comercial@muvin.pe">
                                <i></i>comercial@muvin.pe
                            </a>
                        </div>
                       
                    </div>
                </div>
            </div>
            <div className="link-buttons">
                <a  target="_blank" href="https://web.facebook.com/cosapiinmobiliariaoficial/?_rdc=1&_rdr" className={`link button`}>
                    <div className="redes mask facebook">
                    </div>
                </a>
                <a target="_blank" href="https://www.instagram.com/cosapi.inmobiliaria/" className={`link button`}>
                    <div  className="redes mask instagram">
                    </div>
                </a>
                <a target="_blank" href="https://www.youtube.com/channel/UCfr9B6LMPgZDI6LVSsIA-yQ" className={`link button`}>
                    <div  className="redes mask youtube">
                    </div>
                </a>
                <a href='/brochure-muvin.pdf' target={'_blank'} className='btn-brochure'>
                    <img className='' src={require('../../assets/images/iconos/download-brochure.svg').default} ></img>
                </a>
            </div>
        </div>
    </div>
    )
    }
}
const MapStateProps = (state)=>{
    return(
        {
            value:state.cartStore
        }
    )
}
const OptionsNav = connect(MapStateProps,null)(OptionNav)



export default connect(MapStateProps,null)(Navbar)