// iconos-imagenes
const c_reuniones = require('../../../assets/images/iconos/iconos-sliders/Coworking-reuniones.png'); 
const c_brainstorm = require('../../../assets/images/iconos/iconos-sliders/coworking-brainstorm.png'); 
const c_terraza = require('../../../assets/images/iconos/iconos-sliders/coworking-terraza.png'); 

const f_salavino = require('../../../assets/images/iconos/iconos-sliders/foodies-salavinos.png'); 
const f_horno = require('../../../assets/images/iconos/iconos-sliders/foodies-horno.png'); 
const f_sushi = require('../../../assets/images/iconos/iconos-sliders/foodies-sushi.png'); 
const f_parrilla = require('../../../assets/images/iconos/iconos-sliders/foodies-parrilla.png'); 

const health_salamujer = require('../../../assets/images/iconos/iconos-sliders/health-sala-mujer.png'); 
const health_salamujer2 = require('../../../assets/images/iconos/iconos-sliders/health-sala-mujer2.png'); 
const health_jugos = require('../../../assets/images/iconos/iconos-sliders/health-jugos.png'); 
const health_instagram = require('../../../assets/images/iconos/iconos-sliders/health-camara.png'); 
const health_gimnasio  = require('../../../assets/images/iconos/iconos-sliders/health-gimnasio.png'); 

const juegos_patio  = require('../../../assets/images/iconos/iconos-sliders/juego-patio-ninos.png'); 
const juegos_gamer  = require('../../../assets/images/iconos/iconos-sliders/juego-sala-gamer.png'); 
const juegos_ninos  = require('../../../assets/images/iconos/iconos-sliders/juego-sala-ninos.png'); 
const juegos_ninos_lego  = require('../../../assets/images/iconos/iconos-sliders/juego-sala-ninos-lego.png'); 

const bicleta = require('../../../assets/images/iconos/iconos-sliders/living-bicicleta.png'); 
const lavanderia = require('../../../assets/images/iconos/iconos-sliders/living-lavanderia.png'); 
const living_lobby = require('../../../assets/images/iconos/iconos-sliders/living-lobby.png'); 
const patio = require('../../../assets/images/iconos/iconos-sliders/living-patio.png'); 
const pets = require('../../../assets/images/iconos/iconos-sliders/living-pets.png'); 

const sala_reuniones =  require("../../../assets/images/iconos/iconos-sliders/sala-reuniones.png");
const sala_recording =  require("../../../assets/images/iconos/iconos-sliders/sala-recording.png");
const sala_karaoke =  require("../../../assets/images/iconos/iconos-sliders/sala-karaoke.png");
const fogata =  require("../../../assets/images/iconos/iconos-sliders/fogata.png");
const sala_lounge =  require("../../../assets/images/iconos/iconos-sliders/sala-lounge.png");
const dance =  require("../../../assets/images/iconos/iconos-sliders/dance.png");

const box =  require("../../../assets/images/iconos/iconos-sliders/box.png");


export const secciones = {
    disfruta:{
        slider:{
            iconos:[
                {id:1,icon:health_salamujer,title:"Beauty Room",data:"beauty"},
                {id:2,icon:dance,title:"Sala de Baile",data:"baile"},
                {id:3,icon:box,title:"Sala de Box",data:"box"},
                {id:4,icon:sala_lounge,title:"Sala Lounge",data:"lounge"},

            ],
            imagenes:[
                {img:require("../../../assets/images/diversion/slider/Zona-Ladies-Beauty-Room.jpg"),title:"Beauty room",data:"beauty"},
                {img:require("../../../assets/images/diversion/slider/Sala-de-Baile-Zona-Fitness.jpg"),title:"Sala baile",data:"baile"},
                {img:require("../../../assets/images/diversion/slider/Sala-de-box-Zona-Fitness.jpg"),title:"Sala de Box",data:"box"},
                {img:require("../../../assets/images/diversion/slider/Sala-Lounge-Sociales.jpg"),title:"Sala Lounge",data:"lounge"},
                {img:require("../../../assets/images/diversion/slider/Sala-Lounge-2-Sociales.jpg"),title:"Sala Lounge",data:"lounge"},
            ],
        }
    },
    diversion:{
        ladies:{
            title:"Zona Ladies",    
            iconos:[
                {id:1,icon:health_salamujer,title:"Beauty Room",data:"beauty"},
            ],
            imagenes:[
                {img:require("../../../assets/images/diversion/slider/Zona-Ladies-Beauty-Room.jpg"),title:"Beauty Room",data:"beauty"},
            ]
        },
        fitness:{
            title:"Zona Fitness",
            iconos:[
                {id:1,icon:dance,title:"Sala de Baile",data:"baile"},
                {id:2,icon:health_jugos,title:"Terraza de Jugos",data:"jugos"},
                {id:3,icon:box,title:"Sala de Box",data:"box"}, // no hay 
            ],
            imagenes:[
                {img:require("../../../assets/images/diversion/slider/Sala-de-Baile-Zona-Fitness.jpg"),title:"Sala de Baile",data:"baile"},
                {img:require("../../../assets/images/diversion/slider/Terraza-de-Jugos-Zona-Fitness.jpg"),title:"Terraza de Jugos",data:"jugos"},
                {img:require("../../../assets/images/diversion/slider/Sala-de-box-Zona-Fitness.jpg"),title:"Sala de Box",data:"box"},
            ],
        },
        gourmet:{
            title:"Zona Gourmet",
            iconos:[
                {id:1,icon:f_parrilla,title:"Terraza BBQ",data:"terraza"},
                {id:2,icon:f_sushi,title:"Sushi Bar",data:"sushi"},
                {id:3,icon:f_horno,title:"Terraza de Pizzas",data:"pizza"},
            ],
            imagenes:[
                {img:require("../../../assets/images/diversion/slider/Terraza-BBQ-Zona-Gourmet.jpg"),title:"Terraza BBQ",data:"terraza"},
                {img:require("../../../assets/images/diversion/slider/Sushi-Bar-Zona-Gourmet.jpg"),title:"Sushi Bar",data:"sushi"},
                {img:require("../../../assets/images/diversion/slider/Terraza-de-Pizzas-Zona-Gourmet.jpg"),title:"Terraza de Pizzas",data:"pizza"},
            ],
        },
        coworking:{
            title:"Zona Coworking",
            iconos:[
                {id:1,icon:c_reuniones,title:"Sala de Reuniones",data:"reuniones"},
                {id:2,icon:c_brainstorm,title:"Sala Brainstorming",data:"brainstorn"},
                {id:3,icon:sala_recording,title:"Recording Room",data:"recording"},
                {id:4,icon:c_terraza,title:"Terraza",data:"terraza"}, // no se 
            ],
            imagenes:[
                {img:require("../../../assets/images/diversion/slider/Sala-de-Reuniones-Zona-Coworking.jpg"),title:"Sala de Reuniones",data:"reuniones"},
                {img:require("../../../assets/images/diversion/slider/Sala-Brainstorming-Zona-Coworking.jpg"),title:"Sala Brainstorming",data:"brainstorn"},
                {img:require("../../../assets/images/diversion/slider/Recording-Room-Zona-Coworking.jpg"),title:"Recording Room",data:"recording"},
                {img:require("../../../assets/images/diversion/slider/Terraza-Zona-Coworking.jpg"),title:"Terraza",data:"terraza"},
            ],
        },
        ninos:{
            title:"Zona de Niños",
            iconos:[
                {id:1,icon:juegos_patio,title:"Patio de Juegos",data:"patio"},
                {id:2,icon:juegos_ninos,title:"Sala de niños Mickey",data:"mickey"},
                {id:3,icon:juegos_ninos_lego,title:"Sala de niños Lego",data:"lego"},
            ],
            imagenes:[
                {img:require("../../../assets/images/diversion/slider/Patio-de-Juegos-Zona-de-ninos.jpg"),title:"Patio de Juegos",data:"patio"},
                {img:require("../../../assets/images/diversion/slider/Disney-Zona-de-ninos.jpg"),title:"Sala de niños Mickey",data:"mickey"},
                {img:require("../../../assets/images/diversion/slider/Lego-Zona-de-ninos.jpg"),title:"Sala de niños Lego",data:"lego"},
            ],
        },
        servicios:{
            title:"Zona de Servicios",
            iconos:[
                {id:1,icon:living_lobby,title:"Lobby",data:"lobby"},
                {id:3,icon:pets,title:"Pet Zone",data:"pet"},
            ],
            imagenes:[
                {img:require("../../../assets/images/diversion/slider/Lobby2-Zona-de-Servicios.jpg"),title:"Lobby",data:"lobby"},
                {img:require("../../../assets/images/diversion/slider/Lobby-Zona-de-Servicios.jpg"),title:"Looby 2",data:"lobby"},
                {img:require("../../../assets/images/diversion/slider/Pet-Zone-Zona-de-Servicios.jpg"),title:"Pet Zone",data:"pet"},
            ],
        },
        sociales:{
            title:"Sociales",
            iconos:[
                {id:1,icon:sala_lounge,title:"Sala Lounge",data:"lounge"},
                // {id:2,icon:f_salavino,title:"Wine bar"},
                {id:3,icon:fogata,title:"Zona Fogata",data:"fogata"},
                // {id:4,icon:juegos_gamer,title:"Sala gamer"},
                // {id:5,icon:sala_karaoke,title:"Karaoke"},
            ],
            imagenes:[
                {img:require("../../../assets/images/diversion/slider/Sala-Lounge-Sociales.jpg"),title:"Sala Lounge",data:"lounge"},
                {img:require("../../../assets/images/diversion/slider/Sala-Lounge-2-Sociales.jpg"),title:"Sala Lounge",data:"lounge"},
                // {img:require("../../../assets/images/diversion/slider/Sala-Lounge-2-Sociales.jpg")},
                {img:require("../../../assets/images/diversion/slider/Zona-Fogata-sociales.jpg"),title:"Zona Fogata",data:"fogata"},
                // {img:require("../../../assets/images/diversion/slider/Sala-Lounge-Sociales.jpg")},
                // {img:require("../../../assets/images/diversion/slider/Sala-Lounge-Sociales.jpg")},

            ],
        },
    },
}