import axios from "axios"
import { SperantService } from "./SperantService"

export class LeadService {
    constructor() {
        this._sperant = new SperantService()
    }

    async send(interested) {
        let client = {
            fname: interested.fname,
            lname: interested.lname,
            email: interested.email,
            phone: interested.phone.toString(),
            interest_type_id: 4,
            input_channel_id: 4,
            source_id: 67,
            project_id: 13,
            ...interested.seller_id && { seller_id: interested.seller_id },
            observation: [
                interested.rooms_amount && `Dormitorios: ${interested.rooms_amount}`,
                interested.motive && `Motivo: ${interested.motive}`,
                interested.reservation_date && `Reservación: ${interested.reservation_date} ${interested.reservation_time}`,
                interested.promotion && `Promoción: ${interested.promotion}`,
            ].filter(Boolean).join(", "),
            utm_source: "Orgánico",
        }

        const date = new Date().toLocaleString('es-PE', { timeZone: 'America/Lima' })

        axios.post("https://cors.formulaperu.com/https://script.google.com/macros/s/AKfycbws7GCpc1eEN5ScQ_IisUkLEwKQHvY_XCe5_KEbXA3ytUWVtA/exec", {
            "ss_id": "1CfCxJdJJDWvfmixvX7AdowGGq_LfiBuP823m4Ryj3YA",
            "range": "Muvin!A:XX",
            "values": [[
                date, interested.fname, interested.lname, interested.phone, interested.email, client.observation,
                client.utm_source, client.utm_medium, client.utm_campaign, client.utm_content, client.utm_term
            ]]
        })

        if (interested.promotion?.toLowerCase().includes("patriotas")) client.source_id = 89

        axios.post("https://cors.formulaperu.com/https://hooks.zapier.com/hooks/catch/5020375/ozy1ahn/", {
            "name": `${interested.fname} ${interested.lname}`,
            "email": interested.email,
            "phone": interested.phone,
            "date": new Date().toLocaleString(),
        })

        const response = await this._sperant.createClient(client)

        this.sendToAnalytics(interested)

        return response
    }

    sendToAnalytics(payload) {
        window.dataLayer?.push({
            event: 'Lead',
            ...payload.contact_form && { contact_form: payload.contact_form },
            ...payload.rooms_amount && { rooms_amount: payload.rooms_amount },
            ...payload.motive && { motive: payload.motive },
            ...payload.promo && { promotion: payload.promo },
        })
    }
}