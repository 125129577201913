import { useEffect,useState } from 'react';
import './thanks.scss';
// import { DatoSuccess } from './modalLead/dataSuccess';
const callbackDelay = (callback, delay) => {
	let delayInterval = setInterval(
		() => {
			callback();
			clearInterval(delayInterval);
		},
		delay ? delay : 1000
	);
};
export const Thanks = () => {
	const [animation, setAnimation] = useState('');
	const returnHistory = () => {
		window.history.back();
	};
	useEffect(()=>{
		callbackDelay(() => setAnimation('active'), 500);
	}, [])
	return (
		<main className='thanks'>
			<div className='absolute circle-blur circle-sky circle-position-left'></div>
			<div className='absolute circle-blur circle-purple circle-position-right'></div>
			<div className='content-thanks'>
				<div className='content-background'>
                    <picture className='background-thanks'>
                        <source media="(min-width: 768px)" srcSet={require('../../assets/images/thanks/img-family-desktop.png')} />
					    <img className='background-thanks' src={require('../../assets/images/thanks/img-family-mobile.png')} alt='' />
                    </picture>
				</div>
                <div className='part-info'>
                    <img className='background-points' src={require('../../assets/images/thanks/background-points.svg').default} ></img>
                    <picture className='background-lines'>
                        <source media="(min-width: 768px)" srcSet={require('../../assets/images/thanks/background-lines.png')} />
                        <img className='background-lines' src={require('../../assets/images/thanks/background-lines-mobile.png')} alt='' />
                    </picture>
                    <div className='content-info'>
                        <div className='info'>
                            <div className={'success ' + animation}>
                                <div className='check'>
                                    <div className='content-check'>
                                        <div className='line-1'></div>
                                        <div className='line-2'></div>
                                    </div>
                                </div>
                            </div>
                            <h1 className='title'>Datos enviados</h1>
                            <p className='paragraph'>En breve un asesor se comunicará con usted para más información</p>
                        </div>
                        {/* <a href='https://wa.link/dsegtj' className='button bg-third text-white mb-3 flex'>
                            <div className=' mr-3 mask icon-whatsapp bg-white w-6 h-6'></div>
                            <span className=''>
                                Hablar con un asesor
                            </span>
                        </a> */}
                        <div className='button button-return flex items-center border border-gray-100' onClick={() => returnHistory()}>
                            <span>
                                Volver
                            </span>
                            <div className='mask ml-auto icon-signal-right bg-gray-400 w-5 h-5'></div>
                        </div>
                    </div>
                </div>
			</div>
		</main>
	);
};
