import React, { Component } from 'react'
import "./respaldo.scss"
import ModalMap from '../../UI/modalMap/modalMap';
import MediaQuery from 'react-responsive'
import OwlCarousel from "react-owl-carousel2"
const signal_left =  require("../../../assets/images/Inicio/iconos/signal-left.svg").default
const signal_right =  require("../../../assets/images/Inicio/iconos/signal-right.svg").default

export default class Respaldo extends Component {
    constructor(props){
        super(props)
        this.state = {
            options:{
                items:1,
                rewind: true,
                loop:true,
                nav:true,
                center:false,
                autoWidth:true,
                speed:1000,
                smartSpeed:300,
                dots:true,
                navText: [
                    `<img src=${signal_left}></img>`,
                    `<img src=${signal_right}></img>`
                ],
                activeModal:false,
            },
            slider:[
                {img:require("../../../assets/images/iconos/aeropuerto.jpg"),title:"Ampliación del Aeropuerto Internacional Jorge Chávez",place:'Lima, Perú',by:require("../../../assets/images/cosapi-only.png")},
                {img:require("../../../assets/images/departamentos/render-eqipe.jpg"),title:"Proyecto multifamiliar Épiqe",place:'Lima, Perú',by:require("../../../assets/images/departamentos/COSAPI.png")},
                {img:require("../../../assets/images/departamentos/render-muvin.png"),title:"Proyecto multifamiliar Muvin",place:'Lima, Perú',by:require("../../../assets/images/departamentos/COSAPI.png")},
                {img:require("../../../assets/images/departamentos/render-estadio.png"),title:"Estadio Nacional",place:'Lima, Perú',by:require("../../../assets/images/cosapi-only.png")},
                {img:require("../../../assets/images/departamentos/banco-de-la-nacion.png"),title:"Banco de la Nación",place:'Lima, Perú',by:require("../../../assets/images/cosapi-only.png")},
                {img:require("../../../assets/images/iconos/Prana.png"),title:"Proyecto multifamiliar Prana",place:'Lima, Perú',by:require("../../../assets/images/Inicio/iconos/cosapi-inmobiliaria.png")},
                {img:require("../../../assets/images/iconos/Velia.png"),title:"Proyecto multifamiliar Velia",place:'Lima, Perú',by:require("../../../assets/images/Inicio/iconos/cosapi-inmobiliaria.png")},
            ],
            
            sites:[
                // {name:"pais-1"},
                // {name:"pais-2"},
                // {name:"pais-3"},
                {name:"pais-4"},
                // {name:"pais-5"},
            ]
        }
    }
    componentDidMount(){
        
    }
    activeModal=()=>{
        document.querySelector('.modal-map').classList.add('active')
    }
    render() {
        return (
            <div className="respaldo" id="respaldo">
                <ModalMap active={this.state.activeModal} slider={this.state.slider}></ModalMap>
                <div className="trayectoria">
                    <div className='trayectoria-inf'>
                        <picture className="content-img">
                            <source media="(min-width: 500px)" srcSet={require('../../../assets/images/Inicio/iconos/background-respaldo-desktop.png').default}></source>
                        </picture>
                        <div className="international-title">
                            <h2 className="title">
                                <span className="international-title-bold c-third ultra text-underline">Respaldo internacional </span> 
                                <span class="d-block normal"><span class="border stroke ultra">con más de&nbsp;</span><span class="ultra c-black">60 años de </span></span>
                                <span class="ultra c-black">trayectoria.</span>
                            </h2>
                            <MediaQuery query='(max-width:499px)'>
                                <div className='content-mapa-movil'>
                                    {
                                        this.state.sites.map((e)=>{
                                            return(
                                                <div className={`point ${e.name}`} onClick={()=>{this.activeModal()}}>
                                                </div>
                                            )
                                        })
                                    }
                                    <img className="mapa-movil" src={require("../../../assets/images/Inicio/iconos/map.png")}></img>
                                </div>
                            </MediaQuery>
                        </div>
                        <div className="brands">
                            <div className="respaldo-cosapi">
                                <div className="brand-cosapi">
                                    <a className="img-brand" href="https://www.cosapiinmobiliaria.com.pe/">
                                        <picture className="content-img">
                                            <source media="(min-width: 500px)" srcSet={require('../../../assets/images/Inicio/iconos/brand-cosapi.png')}></source>
                                            <img src={require("../../../assets/images/Inicio/iconos/brand-cosapi.png")}></img>
                                        </picture>
                                    </a>
                                </div>
                                <p className="paragraph --cosapi text-center">Empresa inmobiliaria promotora de Cosapi SA con <strong>más de 60 años en el sector de ingeniería y construcción</strong> a
                                            nivel nacional e internacional, construyendo edificaciones más emblemáticas del Perú.</p>
                            </div>
                            <div className="respaldo-cosapi">
                                <div className="brand-cosapi">
                                    <a className="img-brand" href="https://www.cosapiinmobiliaria.com.pe/">
                                        <picture className="content-img">
                                            <source media="(min-width: 500px)" srcSet={require('../../../assets/images/iconos/Gerpal.svg').default}></source>
                                            <img src={require("../../../assets/images/iconos/Gerpal.svg").default}></img>
                                        </picture>
                                    </a>
                                </div>
                                <p className="paragraph --cosapi text-center">
                                    Empresa inmobiliaria con más de <strong>30 años de experiencia en Perú</strong> y Chile, desarrollando más de 1'170,000 m2 en toda su trayectoria, tanto en proyectos inmobiliarios de vivienda y oficinas.
                                </p>
                            </div>
                        </div>
                        <MediaQuery query='(min-width:500px)'>
                            <div className='content-slider-places'>
                                <OwlCarousel ref={sliderProject => (this.sliderProject = sliderProject)} options={this.state.options} events={this.state.events} >
                                    {
                                        this.state.slider.map((e)=>{
                                            return(
                                                <div className='slide-place'>
                                                    <img className='img-slider' src={e.img} ></img>
                                                    <div className='content-data'>
                                                        <span className='title bold-alt c-primary'>{e.title}</span>
                                                        <span className='by'>{e.place}</span>
                                                        <span className='by-brand'>Desarrollado por:</span>
                                                        <img src={e.by}></img>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </OwlCarousel>
                            </div>
                        </MediaQuery>
                    </div>
                    <MediaQuery query='(min-width:499px)'>
                        <div className='content-mapa-movil'>
                            {
                                this.state.sites.map((e)=>{
                                    return(
                                        <div className={`point ${e.name}`}>
                                        </div>
                                    )
                                })
                            }
                            <img className="mapa-movil" src={require("../../../assets/images/Inicio/iconos/map.png")}></img>
                        </div>
                    </MediaQuery>
                </div>
                
                <div className='content-certificate'>
                    <span className='title-certificate'>Inmobiliaria certificada por</span>
                    <div className='content-best-place container'>
                        <img className='img' src={require('../../../assets/images/Inicio/iconos/best_place.svg').default} ></img>
                        <p className='paragraph'>Cosapi Inmobiliaria recibió la acreditación Best Place to Live, considerada la más importante
                        certificación de calidad del sector inmobiliario en América Latina, debido a la satisfacción
                        de sus clientes con sus productos y el servicio entregado
                        </p>        
                    </div>
                    <span className='title-certificate Intro-Light'>Este proyecto está asociado con</span>
                    <div className='banks'>
                        <div className='bank-item'>
                            <div className='content-img'>
                                <img className='' src={require('../../../assets/images/Inicio/iconos/gerpal.png')} ></img>
                            </div>
                            <p className='paragraph'>
                            Empresa inmobiliaria con <strong>más de 30 años de experiencia
                            en Perú y Chile</strong>, cuenta con amplia experiencia en
                            proyectos de gran magnitud, habiendo desarrollado más
                            de 1'170,000 m2
                            en toda su trayectoria, tanto en proyectos
                            inmobiliarios de vivienda y oficinas.    
                            </p>
                            <a href="https://gerpal.pe/" target='_blank' className="btn-site"><div className="global"></div>Más información</a>
                        </div>
                        <div className='bank-item'>
                            <div className='content-img'>
                                <img className='' src={require('../../../assets/images/Inicio/iconos/banbif.png')} ></img>
                            </div>
                            <p className='paragraph'>BanBif con más de 30 años y consolidado como uno de los cinco primeros bancos en el Perú.</p>
                            <a href="https://www.banbif.com.pe/" target='_blank' className="btn-site"><div className="global"></div>Más información</a>
                        </div>
                    </div>
                </div>
                <div className='background-black'>
                  <img className='background-point-left' src={require('../../../assets/images/Inicio/iconos/background-points-left.png')} ></img>
                </div>
               
            </div>
        )
    }
}
