import React, { Component } from 'react'
import './departamentos.scss'
import Mainpage from '../../paginas/inicio/inicio/Mainpage'
import Slidervirtual from '../../paginas/virtual/slidervirtual/slidervirtual'
import Contacto from '../contacto/contacto'
import OwlCarousel from "react-owl-carousel2"
import $ from 'jquery'
import SectionContact from 'components/paginas/inicio/section-contacto/contacto'
import { connect } from 'react-redux'
const signal_left = require('../../../assets/images/Inicio/iconos/signal-left.svg').default;
const signal_right = require('../../../assets/images/Inicio/iconos/signal-right.svg').default;

export default class Departamentos extends Component {
    constructor(props){
        super(props)
        this.state = {
            image:'',
            options:{
                items: 1,
                rewind: false,
                loop:false,
                nav:false,
                center:false,
                autoplay:false,
                smartSpeed:600,
                autoplaySpeed:2000,
                dots:true,
                navText:[
                    `<div class="signal-left"><img src='${signal_left}'/></div>`,
                    `<div class="signal-left"><img src='${signal_right}'/></div>`
                ]
            },
            events:{
                onDragged:this.onDragged,
                onChanged:this.onChanged,
            },
            imagenes:[
                {index:0,img:require("../../../assets/images/departamentos/slider/Sala.jpg"),title:"Sala comedor",data:"sala-comedor"},
                {index:0,img:require("../../../assets/images/departamentos/slider/Sala-Comedor.jpg"),title:"Sala comedor",data:"sala-comedor"},
                {index:1,img:require("../../../assets/images/departamentos/slider/Escritorio.jpg"),title:"Escritorio",data:"escritorio"},
                {index:2,img:require("../../../assets/images/departamentos/slider/cocina.jpg"),title:"Cocina",data:"cocina"},
                {index:2,img:require("../../../assets/images/departamentos/slider/Cocina-2.jpg"),title:"Cocina",data:"cocina"},
                {index:3,img:require("../../../assets/images/departamentos/slider/Dormitorio-Principal.jpg"),title:"Dormitorio Principal",data:"dorm"},
                {index:3,img:require("../../../assets/images/departamentos/slider/Dormitorio-Secundario.jpg"),title:"Dormitorio Secundario",data:"dorm"},
                {index:3,img:require("../../../assets/images/departamentos/slider/Dormitorio-Secundario-2.jpg"),title:"Dormitorio Secundario",data:"dorm"},
                {index:4,img:require("../../../assets/images/departamentos/slider/bath-new.jpg"),title:"Baño Principal",data:"bath"},
                {index:4,img:require("../../../assets/images/departamentos/slider/bath-main-new.jpg"),title:"Baño Secundario",data:"bath"},
            ],
            acabados:[
                {index:0,icon:require("../../../Paginas/departamento/sala.svg").default,title:"Sala comedor"},
                {index:2,icon:require("../../../Paginas/departamento/sala-de-box.svg").default,title:"Escritorio"},
                {index:3,icon:require("../../../Paginas/departamento/cocina.svg").default,title:"Cocina"},
                {index:5,icon:require("../../../Paginas/departamento/dormitorio.svg").default,title:"Dormitorio"},
                {index:8,icon:require("../../../Paginas/departamento/bath.svg").default,title:"Baño"},
            ]
        }
    }
    componentDidMount(){
        window.scrollTo(0,0)
        var configGallery1 = {
            selector: '.areas',
            thumbnail:true,
            animateThumb: false,
            showThumbByDefault: false,
            subHtmlSelectorRelative: true,
        }
        $('.MyCarouselHorizontal').lightGallery(configGallery1);
        $('html, body').animate({
            scrollTop: $("#sliderVirtual").offset().top
        }, 0);
        $(".scroll-msg-inner").click(function(){
            $('html, body').animate({
                scrollTop: $("#Departamentos").offset().top
            }, 700);
        })
        $('html, body').animate({
            scrollTop: $("#sliderVirtual").offset().top
        }, 0);
        document.title="Proyecto Muvin | Departamentos"
        document.querySelector('meta[name="description"]').setAttribute("content", "Encuentra el depa perfecto para ti en Muvin. Contamos con departamentos de 2 y 3 dormitorios en Lince. Disfruta de los mejores acabados y equipamiento Bosch. Mira los interiores de tu departamento aquí.");
        document.querySelector('meta[name="keywords"]').setAttribute("content", "Proyecto, inmobiliario, Lince, San Isidro, departamento, departamentos, 2 dorms, 3 dorms, 2 dormitorios, 3 dormitorios, casa propia, Bono Verde, Cosapi Inmobiliaria, Gerpal, MiVivienda, Lanzamiento");

    }
    onChanged =(e)=> {
        if(e.page.index >= 0){
            let time = setInterval(() => {
                let index = document.querySelector(".owl-item.active > div").id
                console.log(index)
                $(`.Departamentos .item-destaca`).removeClass("active")
                $(`.Departamentos .item-destaca-${index}`).addClass("active")
                clearInterval(time)
            }, 300);
        }
    }
    move = (e) =>{
        this.refs.car.goTo(e)
        $(`.Departamentos .item-destaca`).removeClass("active")
        $(`.Departamentos .item-destaca-${e.page.index}`).addClass("active")
    }
    showTipologia(id){
        let tipologia = [
            {imagen:require("../../../assets/images/departamentos/tipologias/X01.png")},
            {imagen:require("../../../assets/images/departamentos/tipologias/X02.png")},
            {imagen:require("../../../assets/images/departamentos/tipologias/X03.png")},
            {imagen:require("../../../assets/images/departamentos/tipologias/X04.png")},
            {imagen:require("../../../assets/images/departamentos/tipologias/X05.png")},
            {imagen:require("../../../assets/images/departamentos/tipologias/X06.png")},
            {imagen:require("../../../assets/images/departamentos/tipologias/X07.png")},
            {imagen:require("../../../assets/images/departamentos/tipologias/X08.png")},
            {imagen:require("../../../assets/images/departamentos/tipologias/X09.png")}
        ]
        this.setState({
            image:tipologia[id].imagen,
        })
        $(".view-modal").addClass("active");
        $(".view-modal").click((e)=>{
            console.log(e)
            if(e.target.className == "modal" || e.target.className == "img-tipologia"){
            }else{
                $(".view-modal").removeClass("active");
            }
        })
    }
    moveSlider(id){
        $('.MyCarouselHorizontal').trigger('to.owl.carousel',id-1);
        $('.item').removeClass("active");
        // $('.item')[id-1].classList.add("active");
    }
    movePositionSlider =(index)=>{
        this.refs.car.goTo(index)
    }
    activeDorms(id){
        $(".button").removeClass("active");
        $(`.dorm-${id}`).addClass("active")
        $(`.departamento-ball`).addClass("none")
        $(`.dorm-ball-${id}`).removeClass("none")
        if(id==0){
            $(`.departamento-ball`).removeClass("none")
        }
    }
    render() {
        const close = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 41.67 41.67"><g id="Capa_2" data-name="Capa 2"><g id="Capa_1-2" data-name="Capa 1"><path class="close" d="M41.2,38.67,38.67,41.2a1.58,1.58,0,0,1-2.24,0l-36-36A1.6,1.6,0,0,1,.46,3L3,.46a1.6,1.6,0,0,1,2.24,0l36,36a1.58,1.58,0,0,1,0,2.24" /><path class="close" d="M38.91.7,41,2.76a1.91,1.91,0,0,1,0,2.71L5.48,41a1.93,1.93,0,0,1-2.72,0L.7,38.91a1.93,1.93,0,0,1,0-2.72L36.19.7a1.93,1.93,0,0,1,2.72,0"/></g></g></svg>'
        return (
                
            <section className="Departamentos">
                <img className='line-right-desk' src={require('../../../assets/images/Inicio/iconos/lines-right-desk.png')} ></img>
                <Slidervirtual slider={this.props.inicio}></Slidervirtual>
                <div className="Filtro" id="Departamentos">
                    <div className="view-modal">
                            <div className="btn-close" dangerouslySetInnerHTML={{__html:close}}></div>
                        <div className="modal">
                            <img className="img-tipologia" src={this.state.image}></img>
                        </div>
                    </div>
                <img className='background-point-right' src={require('../../../assets/images/Inicio/iconos/background-lines-right.png')} ></img>
                    <div className='container'>
                        <div className="content-filtro">
                            <div className="img-filtro">
                                <img className="" src={require("../../../assets/images/departamentos/position-tipologies.png")}></img>
                                <div className="departamento-ball dorm-ball-3 ball-1" onClick={()=>{this.showTipologia(0)}}>
                                    <div className="number">1</div>
                                    <div className="add-room">
                                        <span className="title">Tipología X01</span>
                                        <span className="subtitle">3 Dormitorios / 2 Baños</span>
                                        <span className="line-1">Sala / Comedor / Balcón</span>
                                        <span className="line-2">Cocina / Lavandería</span>
                                        <span className="area">Área: 69.12 m²</span>
                                        <img className="triangule" src={require("../../../assets/images/departamentos/triangule.png")}></img>
                                    </div>
                                </div>
                                <div className="departamento-ball dorm-ball-3 ball-2"  onClick={()=>{this.showTipologia(1)}}>
                                    <div className="number">2</div>
                                    <div className="add-room">
                                        <span className="title">Tipología X02</span>
                                        <span className="subtitle">3 Dormitorios / 2 Baños</span>
                                        <span className="line-1">Sala / Comedor / Balcón</span>
                                        <span className="line-2">Cocina / Lavandería</span>
                                        <span className="area">Área: 67.42 m²</span>
                                        <img className="triangule" src={require("../../../assets/images/departamentos/triangule.png")}></img>
                                    </div>
                                </div>
                                <div className="departamento-ball dorm-ball-2 ball-3"  onClick={()=>{this.showTipologia(2)}}>
                                    <div className="number">3</div>
                                    <div className="add-room">
                                        <span className="title">Tipología X03</span>
                                        <span className="subtitle">2 Dormitorios / 2 Baños</span>
                                        <span className="line-1">Sala / Comedor / Balcón</span>
                                        <span className="line-2">Cocina / Lavandería</span>
                                        <span className="area">Área: 56.47 m²</span>
                                        <img className="triangule" src={require("../../../assets/images/departamentos/triangule.png")}></img>
                                    </div>
                                </div>
                                <div className="departamento-ball dorm-ball-2 ball-4"  onClick={()=>{this.showTipologia(3)}}>
                                    <div className="number">4</div>
                                    <div className="add-room">
                                        <span className="title">Tipología X04</span>
                                        <span className="subtitle">1 Dormitorio / 2 Baños</span>
                                        <span className="line-1">Sala / Comedor / Balcón / Cocina</span>
                                        <span className="line-2"> Lavandería / Sala de estar</span>
                                        <span className="area">Área: 45.12 m²</span>
                                        <img className="triangule" src={require("../../../assets/images/departamentos/triangule.png")}></img>
                                    </div>
                                </div>
                                <div className="departamento-ball dorm-ball-2 ball-5"  onClick={()=>{this.showTipologia(4)}}>
                                    <div className="number">5</div>
                                    <div className="add-room">
                                        <span className="title">Tipología X05</span>
                                        <span className="subtitle">1 Dormitorio / 2 Baños</span>
                                        <span className="line-1">Sala / Comedor / Balcón / Cocina</span>
                                        <span className="line-2">Lavandería / Sala de estar</span>
                                        <span className="area">Área: 47.31 m²</span>
                                        <img className="triangule" src={require("../../../assets/images/departamentos/triangule.png")}></img>
                                    </div>
                                </div>
                                <div className="departamento-ball dorm-ball-3 ball-6"  onClick={()=>{this.showTipologia(5)}}>
                                    <div className="number">6</div>
                                    <div className="add-room">
                                        <span className="title">Tipología X06</span>
                                        <span className="subtitle">2 Dormitorios / 2 Baños</span>
                                        <span className="line-1">Sala / Comedor / Balcón / Cocina</span>
                                        <span className="line-2">Lavandería / Sala de estar</span>
                                        <span className="area">Área: 56.53 m²</span>
                                        <img className="triangule" src={require("../../../assets/images/departamentos/triangule.png")}></img>
                                    </div>
                                </div>
                                <div className="departamento-ball dorm-ball-2 ball-7"  onClick={()=>{this.showTipologia(6)}}>
                                    <div className="number">7</div>
                                    <div className="add-room">
                                        <span className="title">Tipología X07</span>
                                        <span className="subtitle">1 Dormitorio / 2 Baños</span>
                                        <span className="line-1">Sala / Comedor / Balcón / Cocina</span>
                                        <span className="line-2">Lavandería / Sala de estar</span>
                                        <span className="area">Área: 48.46 m²</span>
                                        <img className="triangule" src={require("../../../assets/images/departamentos/triangule.png")}></img>
                                    </div>
                                </div>
                                <div className="departamento-ball dorm-ball-3 ball-8"  onClick={()=>{this.showTipologia(7)}}>
                                    <div className="number">8</div>
                                    <div className="add-room">
                                        <span className="title">Tipología X08</span>
                                        <span className="subtitle">3 Dormitorios / 2 Baños</span>
                                        <span className="line-1">Sala / Comedor / Balcón </span>
                                        <span className="line-2">Cocina / Lavandería</span>
                                        <span className="area">Área: 63.74 m²</span>
                                        <img className="triangule" src={require("../../../assets/images/departamentos/triangule.png")}></img>
                                    </div>
                                </div>
                                <div className="departamento-ball dorm-ball-2 ball-9"  onClick={()=>{this.showTipologia(8)}}>
                                    <div className="number">9</div>
                                    <div className="add-room">
                                        <span className="title">Tipología X09</span>
                                        <span className="subtitle">2 Dormitorios / 2 Baños</span>
                                        <span className="line-1">Sala / Comedor / Balcón</span>
                                        <span className="line-2">Cocina / Lavandería</span>
                                        <span className="area">Área: 55.97 m²</span>
                                        <img className="triangule" src={require("../../../assets/images/departamentos/triangule.png")}></img>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="content-button">
                            <div className="button dorm-0 active ultra" onClick={()=>{this.activeDorms(0)}}>Ver todo </div>
                            <div className="button dorm dorm-2 ultra" onClick={()=>{this.activeDorms(2)}}>2 dorms </div>
                            <div className="button dorm dorm-3 ultra" onClick={()=>{this.activeDorms(3)}}>3 dorms </div>
                        </div>
                    </div>
                </div>
                <div className='Secciones'>
                    <div className='background-black'>
                    </div>
                    <div className='Destaca Destaca-1 flex-row'>
                        <div className="Info">
                            <div className="parrafo">
                            Disfruta de los mejores acabados y equipamiento
                            </div>
                            <div className='img-acabados'>
                                <img className='' src={require('../../../Paginas/departamento/boshmuvin-departamentos.png')}></img>
                            </div>
                            <ControlsStore 
                                data={this.state.acabados} 
                                move={this.movePositionSlider}
                            ></ControlsStore>
                        </div>
                        <div className="Slider">
                            <div className="relative all-slider">
                                <div className="form-slider">
                                    <OwlCarousel ref="car" options={this.state.options} events={this.state.events} className="owl-carousel MyCarouselHorizontal2 slider-disfruta owl-theme owl-loaded owl-drag" id="carousel-disfruta">
                                        {this.state.imagenes.map((element,index)=>{
                                            return(
                                                <div className="slider-img slider-disfrita-img" id={element.index} data-src={element.img} href={element.img} data-exthumbimage={element.img}
                                                data-sub-html={"#index-disfruta"+index}>
                                                    <div className="d-block slider-img">
                                                        <img className="slider-img" src={element.img} data-icon={element.data}></img>
                                                    </div>
                                                    <div id={"index-disfruta"+index}>
                                                        <h4>{element.title}</h4>
                                                    </div>
                                                </div>
                                                )
                                            })}
                                    </OwlCarousel>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <SectionContact></SectionContact>
            </section>
        )
    }
}


class Controls extends Component {
    constructor(props){
        super(props);
    }
    moveDisfruta(e){
        this.props.changeSlide(e)
    }
    render(){
        return(
            <div className="selectores flex-row">
                {
                this.props.data.map((element,index)=>{
                    return (
                        <div key={index+"btn"} className={`item item-destaca item-destaca-${index} ${this.props.value.index == index ? "active" : ""}`} key={element.id} onClick={()=>{this.props.move(element.index)}}>
                            <div className="img">
                                <div className="content-radius">
                                    <img className="icon-img" src={element.icon}></img>
                                </div>
                            </div>
                            <p className="title c-white">{element.title}</p>
                        </div>
                    )
                })}
            </div>
        )
    }
}
const MapStateProps=(state)=>{
    return(
        {
            value:state.sliderStore
        }
    )
}
const ControlsStore = connect(MapStateProps,null)(Controls)