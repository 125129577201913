import { createSlice } from '@reduxjs/toolkit';
const  initialState = {
    value: '',
    data:{},
}
export const counterStore = createSlice({
    name:"counterStore",
    initialState,
    reducers:{
        changeCounter:(state,value)=>{
            state.value = value.payload.value
            state.data = value.payload.data
        }
    }

})
export const { changeCounter } = counterStore.actions
export default counterStore.reducer