import React, { Component } from 'react'
import "./atributos.scss"
import OwlCarousel from "react-owl-carousel2"
import 'lightgallery/dist/css/lightgallery.css';
import 'lightgallery/dist/js/lightgallery';
import 'lightgallery/dist/js/lightgallery-all';
import { connect } from 'react-redux'
import $ from 'jquery'
import { addRefers,moveLeft,moveRight,addRefersControl,addRefersPoint,moveDiversion} from '../../../data/sliderStore';
import MediaQuery from 'react-responsive';
const signal_left = require('../../../assets/images/Inicio/iconos/signal-left.svg').default;
const signal_right = require('../../../assets/images/Inicio/iconos/signal-right.svg').default;
class SeccionesSlider extends Component {
    constructor(props){
        super(props)
        this.state = {
            section:"sociales",
            count:0,
            options:{
                items: 1,
                rewind: false,
                loop:false,
                nav:false,
                center:false,
                autoplay:false,
                smartSpeed:600,
                autoplaySpeed:2000,
                dots:true,
                navText:[
                    `<div class="signal-left"><img src='${signal_left}'/></div>`,
                    `<div class="signal-left"><img src='${signal_right}'/></div>`
                ]
            },
           
            events:{
                onDragged:this.onDragged,
                onChanged:this.onChanged,
            },
            activeDiversion:0,
            imagenesDisfruta:[
                {icon:require("../../../assets/images/Inicio/iconos/beauty.svg").default,title:"Beauty Room",img:require("../../../assets/images/diversion/slider/Zona-Ladies-Beauty-Room.jpg"),title:"Beauty room",data:"beauty"},
                {icon:require("../../../assets/images/Inicio/iconos/slider/zona-de-baile.svg").default,title:"Sala de Baile",img:require("../../../assets/images/diversion/slider/Sala-de-Baile-Zona-Fitness.jpg"),title:"Sala baile",data:"baile"},
                {icon:require("../../../assets/images/Inicio/iconos/slider/zona-box.svg").default,title:"Sala de Box",img:require("../../../assets/images/diversion/slider/Sala-de-box-Zona-Fitness.jpg"),title:"Sala de Box",data:"box"},
                {icon:require("../../../assets/images/Inicio/iconos/slider/sala-lounge.svg").default,title:"Sala Lounge",img:require("../../../assets/images/diversion/slider/Sala-Lounge-Sociales.jpg"),title:"Sala Lounge",data:"lounge"},
            ],
            imagenesDiversion:[
                {index:0,icon:require("../../../assets/images/Inicio/iconos/slider/beauty-room.svg").default,img:require("../../../assets/images/diversion/slider/Zona-Ladies-Beauty-Room.jpg"),title:"Beauty Room",data:"beauty"},
                {index:1,icon:require("../../../assets/images/Inicio/iconos/slider/zona-de-baile.svg").default,img:require("../../../assets/images/diversion/slider/Sala-de-Baile-Zona-Fitness.jpg"),title:"Sala de Baile",data:"baile"},
                {index:2,icon:require("../../../assets/images/Inicio/iconos/slider/terraza-de-jugos.svg").default,img:require("../../../assets/images/diversion/slider/Terraza-de-Jugos-Zona-Fitness.jpg"),title:"Terraza de Jugos",data:"jugos"},
                {index:3,icon:require("../../../assets/images/Inicio/iconos/slider/zona-box.svg").default,img:require("../../../assets/images/diversion/slider/Sala-de-box-Zona-Fitness.jpg"),title:"Sala de Box",data:"box"},
                {index:4,icon:require("../../../assets/images/Inicio/iconos/slider/terraza-bbq.svg").default,img:require("../../../assets/images/diversion/slider/Terraza-BBQ-Zona-Gourmet.jpg"),title:"Terraza BBQ",data:"terraza"},
                {index:5,icon:require("../../../assets/images/Inicio/iconos/slider/sushi-bar.svg").default,img:require("../../../assets/images/diversion/slider/Sushi-Bar-Zona-Gourmet.jpg"),title:"Sushi Bar",data:"sushi"},
                {index:6,icon:require("../../../assets/images/Inicio/iconos/slider/terraza-de-pizzas.svg").default,img:require("../../../assets/images/diversion/slider/Terraza-de-Pizzas-Zona-Gourmet.jpg"),title:"Terraza de Pizzas",data:"pizza"},
                {index:7,icon:require("../../../assets/images/Inicio/iconos/slider/sala-de-reuniones.svg").default,img:require("../../../assets/images/diversion/slider/Sala-de-Reuniones-Zona-Coworking.jpg"),title:"Sala de Reuniones",data:"reuniones"},
                {index:8,icon:require("../../../assets/images/Inicio/iconos/slider/sala-brainstorming.svg").default,img:require("../../../assets/images/diversion/slider/Sala-Brainstorming-Zona-Coworking.jpg"),title:"Sala Brainstorming",data:"brainstorn"},
                {index:9,icon:require("../../../assets/images/Inicio/iconos/slider/recording-room.svg").default,img:require("../../../assets/images/diversion/slider/Recording-Room-Zona-Coworking.jpg"),title:"Recording Room",data:"recording"},
                {index:10,icon:require("../../../assets/images/Inicio/iconos/slider/terraza.svg").default,img:require("../../../assets/images/diversion/slider/Terraza-Zona-Coworking.jpg"),title:"Terraza",data:"terraza"},
                {index:11,icon:require("../../../assets/images/Inicio/iconos/slider/disney-room.svg").default,img:require("../../../assets/images/diversion/slider/Disney-Zona-de-ninos.jpg"),title:"Sala de niños Mickey",data:"mickey"},
                {index:12,icon:require("../../../assets/images/Inicio/iconos/slider/patio-de-juegos.svg").default,img:require("../../../assets/images/diversion/slider/Patio-de-Juegos-Zona-de-ninos.jpg"),title:"Patio de Juegos",data:"patio"},
                {index:13,icon:require("../../../assets/images/Inicio/iconos/slider/lego-room.svg").default,img:require("../../../assets/images/diversion/slider/Lego-Zona-de-ninos.jpg"),title:"Sala de niños Lego",data:"lego"},
                {index:14,icon:require("../../../assets/images/Inicio/iconos/slider/lobby.svg").default,img:require("../../../assets/images/diversion/slider/Lobby2-Zona-de-Servicios.jpg"),title:"Lobby",data:"lobby"},
                {index:15,icon:require("../../../assets/images/Inicio/iconos/slider/lobby.svg").default,img:require("../../../assets/images/diversion/slider/Lobby-Zona-de-Servicios.jpg"),title:"Looby 2",data:"lobby"},
                {index:16,icon:require("../../../assets/images/Inicio/iconos/slider/zona-de-mascotas.svg").default,img:require("../../../assets/images/diversion/slider/Pet-Zone-Zona-de-Servicios.jpg"),title:"Pet Zone",data:"pet"},
                {index:17,icon:require("../../../assets/images/Inicio/iconos/slider/sala-lounge.svg").default,img:require("../../../assets/images/diversion/slider/Sala-Lounge-Sociales.jpg"),title:"Sala Lounge",data:"lounge"},
                {index:18,icon:require("../../../assets/images/Inicio/iconos/slider/sala-lounge.svg").default,img:require("../../../assets/images/diversion/slider/Sala-Lounge-2-Sociales.jpg"),title:"Sala Lounge",data:"lounge"},
                {index:19,icon:require("../../../assets/images/Inicio/iconos/slider/terraza-de-pizzas.svg").default,img:require("../../../assets/images/diversion/slider/Zona-Fogata-sociales.jpg"),title:"Zona Fogata",data:"fogata"},
            ],
        }
    }
    componentDidMount(){
        $(".item-destaca.item-0").addClass("activated")
        $(".ball.ball-0").addClass("active")
        $('.content-slider').lightGallery({
            selector: '.slider-img-form',
            thumbnail:true,
            animateThumb: false,
            showThumbByDefault: false,
            subHtmlSelectorRelative: true,
            rotate:false,
            actualSize:false,
            download:true,
            pinterest:false,
            googlePlus:false,
            twitter:false,
        });
    } 
    onChanged =(e)=> {
        if(e.page.index >= 0){
           
        }
    }
    moveDiversion =(e)=>{
        console.log("check",this.props)
        // this.refs.car.goTo(e)
    }

    render() {
        return (
        <div className="Secciones">
            <img className='line-right-desk' src={require('../../../assets/images/Inicio/iconos/lines-right-desk.png')} ></img>
              
            <div className="Destaca Destaca-2 flex-row" id="galeria-destaca">  
                <div className="Info">
                    <div className='title'>
                        <span className='title-1 c-primary text-underline ultra'>Espacios para vivir</span>
                        <span className='c-black ultra'>experiencias distintas</span>
                    </div>
                    <div className="selectores flex-row">
                        <ControlsDiversionStore
                            element={ this.state.imagenesDiversion}
                            move={this.moveDiversion}
                        /> 
                    </div>
                   <MediaQuery query='(min-width:500px)'>
                        <div className="controls">
                            <PointsStore images={this.state.imagenesDiversion}></PointsStore>
                        </div>
                   </MediaQuery>
                    
                </div>
                <div className="Slider">
                    <div className="relative all-slider">
                        <FormSliderStore images={this.state.imagenesDiversion}></FormSliderStore>
                    </div>
                </div>
                <MediaQuery query='(max-width:499px)'>
                    <div className="controls">
                        <PointsStore images={this.state.imagenesDiversion}></PointsStore>
                    </div>
                </MediaQuery>
            </div>
        </div>
        )
    }
}

export default  connect(null,null)(SeccionesSlider)


class FormSlider extends Component {
    constructor(props){
        super(props);
        this.state= {
            options:{
                items: 1,
                rewind: false,
                loop:false,
                nav:false,
                center:false,
                autoplay:false,
                smartSpeed:600,
                autoplaySpeed:2000,
                dots:true,
                navText:[
                    ` `,
                    ``
                ]
            },
            events:{
                onDragged:this.onDragged,
                onChanged:this.onChanged,
            },
        }
    }
    componentDidMount(){
        this.props.addRefers(this.sliderForm)
    }
    onChanged =(e)=> {
        let images = this.props.images
        if(e.page.index >= 0){
            $(`.content-selects .item`).removeClass("activated")
            $(`.content-selects .item-${images[e.page.index].index}`).addClass("activated")
            //points
            $(`.points .ball`).removeClass("active")
            $(`.points .ball-${images[e.page.index].index}`).addClass("active")

        
            let index = parseInt(document.querySelector(".activated").dataset.index);
            let actives = document.querySelectorAll("#carousel-disfruta .owl-item.active")
            if(index > parseInt(actives[actives.length-1].childNodes[0].dataset.index)){
                this.props.value.referControl.next()
                this.props.value.referPoint.next()
            }
            if(index < parseInt(actives[0].childNodes[0].dataset.index)){
                this.props.value.referControl.prev()
                this.props.value.referPoint.prev()
            }
        }
    }

    render(){
        return(
            <div className="form-slider">
                <div className={`content-slider`}>
                    <OwlCarousel ref={sliderForm => this.sliderForm = sliderForm} options={this.state.options} events={this.state.events} className="owl-carousel MyCarouselHorizontal2 slider-disfruta owl-theme owl-loaded owl-drag">
                        {
                            this.props.images.map((element,index)=>{
                            return(
                                <div className="slider-img slider-img-form slider-disfrita-img" data-src={element.img} href={element.img}>
                                    <div className="d-block slider-img">
                                        <img className="slider-img" src={element.img} data-icon={element.data}></img>
                                    </div>
                                </div>
                                )
                            })
                        }
                    </OwlCarousel>
                </div>
            </div>
        )
    }
}

const MapStateProps=(state)=>{
    return(
        {
            value:state.sliderStore
        }
    )
}
const FormSliderStore = connect(MapStateProps,{addRefers})(FormSlider)

class ControlsDiversion extends Component {
    constructor(props){
        super(props);
        this.state = {
            init:0,
            optionsItems:{
                items:4,
                rewind: false,
                loop:false,
                nav:false,
                center:false,
                autoplay:false,
                smartSpeed:600,
                autoplaySpeed:2000,
                dots:true,
                mouseDrag:false,
                responsive:{
                    0:{
                        items:3
                    },
                    500:{
                        items:4
                    },

                }
            },
        }
    }
    componentDidMount(){
        this.props.addRefersControl(this.controls)
    }
    moveRight =()=>{
        this.props.moveRight()
    }
    moveLeft =()=>{
        this.props.moveLeft()
        
    }
    render(){
        return(
            <div className={`content-selects ${this.props.value.activeSlideDiversion == this.props.index ? "active" : ""} content-select-${this.props.index}`}>
                <div class="signal-left" onClick={()=>this.moveLeft()}><img src={signal_left}/></div>
                <OwlCarousel ref={controls => this.controls = controls} options={this.state.optionsItems} events={this.state.events} className="owl-carousel MyCarouselHorizontal2 slider-disfruta owl-theme owl-loaded owl-drag" id="carousel-disfruta">
                    {
                    this.props.element.map((item,index)=>{
                            return (
                                <div className={`item item-destaca item-${item.index}`} data-index={index} key={item.title} onClick={()=>{this.props.moveDiversion(index);}}>
                                    <div className="img">
                                        <div className="content-radius">
                                            <img className="icon-img" src={item.icon}></img>
                                        </div>
                                    </div>
                                    <p className="title">{item.title}</p>
                                </div>
                            )
                        })
                    }
                </OwlCarousel>
                <div class="signal-right" onClick={()=>this.moveRight()}><img src={signal_right}/></div>

            </div>
        )
    }
}

const ControlsDiversionStore = connect(MapStateProps,{moveRight,moveLeft,addRefersControl,moveDiversion})(ControlsDiversion);

class Points extends Component{
    constructor(props){
        super(props)
        this.state = {
            optionsItems:{
                items: 4,
                rewind: false,
                loop:false,
                nav:false,
                center:false,
                autoplay:false,
                smartSpeed:600,
                autoplaySpeed:2000,
                dots:true,
                mouseDrag:false
            },
        }
    }
    componentDidMount(){
        this.props.addRefersPoint(this.controls)
    }
    render(){
        return(
            <div className='points'>
                <OwlCarousel ref={controls => this.controls = controls} options={this.state.optionsItems} events={this.state.events} className="owl-carousel MyCarouselHorizontal2 slider-disfruta owl-theme owl-loaded owl-drag">
                {
                    this.props.images.map((e,index)=>{
                        return(
                                <div className={`ball ball-${index}`} data-index={index}>

                                </div>
                        )
                    })
                }
                </OwlCarousel>
            </div>
        )
    }
}
const PointsStore = connect(MapStateProps,{addRefersPoint})(Points);
