import { createSlice } from '@reduxjs/toolkit';
import $ from 'jquery'
const  initialState = {
    index:0,
    activeControl:"Zona Coworking",
    activeDiversion:0,
    activeSlideDiversion:0,
    refers:"",
    referControl:"",
    referPoint:"",
    imagenesDiversion:[
        {
            title:"Zona Ladies",
            ref:"Zona_Ladies",
            imagenes:[
                {icon:require("../assets/images/Inicio/iconos/slider/beauty-room.svg").default,img:require("../assets/images/diversion/slider/Zona-Ladies-Beauty-Room.jpg"),title:"Beauty Room",data:"beauty"},
            ]
        },
        {
            title:"Zona Fitness",
            ref:"Zona_Fitness",
            imagenes:[
                {icon:require("../assets/images/Inicio/iconos/slider/zona-de-baile.svg").default,img:require("../assets/images/diversion/slider/Sala-de-Baile-Zona-Fitness.jpg"),title:"Sala de Baile",data:"baile"},
                {icon:require("../assets/images/Inicio/iconos/slider/terraza-de-jugos.svg").default,img:require("../assets/images/diversion/slider/Terraza-de-Jugos-Zona-Fitness.jpg"),title:"Terraza de Jugos",data:"jugos"},
                {icon:require("../assets/images/Inicio/iconos/slider/zona-box.svg").default,img:require("../assets/images/diversion/slider/Sala-de-box-Zona-Fitness.jpg"),title:"Sala de Box",data:"box"},
            ],
        },
        {
            title:"Zona Gourmet",
            ref:"Zona_Gourmet",
            imagenes:[
                {icon:require("../assets/images/Inicio/iconos/slider/terraza-bbq.svg").default,img:require("../assets/images/diversion/slider/Terraza-BBQ-Zona-Gourmet.jpg"),title:"Terraza BBQ",data:"terraza"},
                {icon:require("../assets/images/Inicio/iconos/slider/sushi-bar.svg").default,img:require("../assets/images/diversion/slider/Sushi-Bar-Zona-Gourmet.jpg"),title:"Sushi Bar",data:"sushi"},
                {icon:require("../assets/images/Inicio/iconos/slider/sushi-bar.svg").default,img:require("../assets/images/diversion/slider/Terraza-de-Pizzas-Zona-Gourmet.jpg"),title:"Terraza de Pizzas",data:"pizza"},
            ],
        },
        {
            title:"Zona Coworking",
            ref:"Zona_Coworking",
            imagenes:[
                {icon:require("../assets/images/Inicio/iconos/slider/sala-de-reuniones.svg").default,img:require("../assets/images/diversion/slider/Sala-de-Reuniones-Zona-Coworking.jpg"),title:"Sala de Reuniones",data:"reuniones"},
                {icon:require("../assets/images/Inicio/iconos/slider/sala-brainstorming.svg").default,img:require("../assets/images/diversion/slider/Sala-Brainstorming-Zona-Coworking.jpg"),title:"Sala Brainstorming",data:"brainstorn"},
                {icon:require("../assets/images/Inicio/iconos/slider/recording-room.svg").default,img:require("../assets/images/diversion/slider/Recording-Room-Zona-Coworking.jpg"),title:"Recording Room",data:"recording"},
                {icon:require("../assets/images/Inicio/iconos/slider/terraza.svg").default,img:require("../assets/images/diversion/slider/Terraza-Zona-Coworking.jpg"),title:"Terraza",data:"terraza"},
            ],
        },
        {
            title:"Zona de Niños",
            ref:"Zona_child",
            imagenes:[
                {icon:require("../assets/images/Inicio/iconos/slider/patio-de-juegos.svg").default,img:require("../assets/images/diversion/slider/Patio-de-Juegos-Zona-de-ninos.jpg"),title:"Patio de Juegos",data:"patio"},
                {icon:require("../assets/images/Inicio/iconos/slider/disney-room.svg").default,img:require("../assets/images/diversion/slider/Disney-Zona-de-ninos.jpg"),title:"Sala de niños Mickey",data:"mickey"},
                {icon:require("../assets/images/Inicio/iconos/slider/lego-room.svg").default,img:require("../assets/images/diversion/slider/Lego-Zona-de-ninos.jpg"),title:"Sala de niños Lego",data:"lego"},
            ],
        },
        {
            title:"Zona de Servicios",
            ref:"Servicios",
            imagenes:[
                {icon:require("../assets/images/Inicio/iconos/slider/lobby.svg").default,img:require("../assets/images/diversion/slider/Lobby2-Zona-de-Servicios.jpg"),title:"Lobby",data:"lobby"},
                {icon:require("../assets/images/Inicio/iconos/slider/lobby.svg").default,img:require("../assets/images/diversion/slider/Lobby-Zona-de-Servicios.jpg"),title:"Looby 2",data:"lobby"},
                {icon:require("../assets/images/Inicio/iconos/slider/zona-de-mascotas.svg").default,img:require("../assets/images/diversion/slider/Pet-Zone-Zona-de-Servicios.jpg"),title:"Pet Zone",data:"pet"},
            ],
        },
        {
            title:"Sociales",
            ref:"Sociales",
            imagenes:[
                {icon:require("../assets/images/Inicio/iconos/slider/sala-lounge.svg").default,img:require("../assets/images/diversion/slider/Sala-Lounge-Sociales.jpg"),title:"Sala Lounge",data:"lounge"},
                {icon:require("../assets/images/Inicio/iconos/slider/sala-lounge.svg").default,img:require("../assets/images/diversion/slider/Sala-Lounge-2-Sociales.jpg"),title:"Sala Lounge",data:"lounge"},
                {icon:require("../assets/images/Inicio/iconos/slider/terraza-de-pizzas.svg").default,img:require("../assets/images/diversion/slider/Zona-Fogata-sociales.jpg"),title:"Zona Fogata",data:"fogata"},

            ],
        },
    ],
}
export const sliderStore = createSlice({
    name:"sliderStore",
    initialState,
    reducers:{
        moveRight:(state,value)=>{
            state.refers.next()
        },
        moveLeft:(state,value)=>{
            state.refers.prev()
        },
        addRefers:(state,value)=>{
            state.refers = value.payload
        },
        addRefersControl:(state,value)=>{
            state.referControl = value.payload
        },
        addRefersPoint:(state,value)=>{
            state.referPoint = value.payload
        },
        moveDiversion:(state,value)=>{
            state.refers.goTo(value.payload)  
        }
    }

})
export const { addRefers,moveLeft,moveRight,addRefersControl,addRefersPoint,moveDiversion} = sliderStore.actions
export default sliderStore.reducer