import React from "react"
import { useEffect } from "react";
import { useRef } from "react"
import {Helmet} from "react-helmet";
import './error404.scss'
export default function Error404 (){
    const cohete = useRef(null)
    const handleViewer = (e) => {
        if(e[0].isIntersecting){
            let interval =  setInterval(() => {
                clearInterval(interval)
                cohete.current.classList.add('active')
            }, 3000);
        }
    }
    useEffect(()=>{
        let options = {
            root: null,
            rootMargin: '0px',
            threshold: 1.0
        }
          
        let observer = new IntersectionObserver(handleViewer, options);
        observer.observe(document.querySelector('.cohete'))
    },[])
    return (
        <div className="error404">
            <Helmet>
                <meta name="robots" content="noindex"/>
            </Helmet>
            <div className='text-error'>
                <span className='error c-primary bold'>ERROR</span>
                <span className="c-primary ultra">4       <img className='cohete' ref={cohete} src={require('../../../assets/images/error/cohete.png')}></img>4</span>
            </div>
            <p className='paragraph paragraph-1 c-primary GothamRounded-Bold'>LA PÁGINA QUE BUSCAS ACABA DE PARTIR</p>
            <p className='paragraph paragraph-2 c-primary GothamRounded-Book'>¡No te preocupes!, revisa la dirección o vuelve a la página de inicio. </p>
            <a href="/" className="b-secondary button">VOLVER</a>
        </div>
    )
}