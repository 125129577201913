import React, { Component } from 'react'
import OwlCarousel from "react-owl-carousel2"
import $ from  'jquery'
const signal_left = require('../../assets/images/Inicio/iconos/signal-left.svg').default;
const signal_right = require('../../assets/images/Inicio/iconos/signal-right.svg').default;

export default class Slidersite extends Component {
    constructor(props){
        super(props)
        this.state = {
            options:{
                items: 3,
                rewind: false,
                loop:false,
                nav:false,
                center:true,
                autoplay:false,
                smartSpeed:600,
                autoplaySpeed:2000,
                dots:true,
                autoWidth:true,
                margin:10,
                navText:[
                    `<div class="signal-left"><img src='${signal_left}'/></div>`,
                    `<div class="signal-left"><img src='${signal_right}'/></div>`
                ],
                responsive:{
                    0:{
                        items:1,
                        center:false,
                    },
                    500:{
                        items:1,
                        loop:true,
                    }
                }
            },
        }
    }
    componentDidMount(){
        $('.Contacto-areas .content-slider-places').lightGallery({
            selector: '.slide-place',
            thumbnail:true,
            animateThumb: false,
            showThumbByDefault: false,
            subHtmlSelectorRelative: true,
            rotate:false,
            actualSize:false,
            download:true,
            pinterest:false,
            googlePlus:false,
            twitter:false,
        });
    }
    render() {
        return (
            <div className='content-slider-places'>
                <OwlCarousel ref={sliderProject => (this.sliderProject = sliderProject)} options={this.state.options} events={this.state.events} >
                    {
                        this.props.slider.map((e)=>{
                            return(
                                <div className='slide-place' href={e.imagen}>
                                    <img className='img-slider' src={e.imagen} ></img>
                                    <div className='title-slide'>
                                        {e.title}
                                    </div>
                                </div>
                            )
                        })
                    }
                </OwlCarousel>
            </div>
        )
    }
}
