// import {promociones} from './promociones'
export const inicio = {
    page:"Main-page",
    img:[
        {img:require("../assets/images/Inicio/background-inicio.jpg"),imgmovil:require("../assets/images/Inicio/background-inicio-movil.jpg")}
    ]
}
export const promociones = {
    page:'promociones',
    img:[
        // {
        //     index:0,
        //     img:require("../assets/images/promociones/promo_muvin.png"),
        //     imgmovil:require("../assets/images/promociones/promo_muvin_móvil.png"),
        //     date:{from:'07/27/2022',to:'08/05/2022'},
        //     name:'Precios Recontra Patriotas',
        //     fields: {
        //         motive: false,
        //         rooms_amount: false,
        //         reservation_date: [
        //             ["", "Reserva tu cita"],
        //             ["28 de Julio", "28 de Julio"],
        //             ["29 de Julio", "29 de Julio"],
        //             ["30 de Julio", "30 de Julio"],
        //             ["31 de Julio", "31 de Julio"],
        //             ["1 de Agosto", "1 de Agosto"],
        //             ["2 de Agosto", "2 de Agosto"],
        //             ["3 de Agosto", "3 de Agosto"],
        //             ["4 de Agosto", "4 de Agosto"],
        //             ["5 de Agosto", "5 de Agosto"],
        //         ],
        //         reservation_time: [
        //             ["", "Hora de la cita"],
        //             ["10:00 AM", "10:00 AM"],
        //             ["11:00 AM", "11:00 AM"],
        //             ["12:00 AM", "12:00 AM"],
        //             ["1:00 PM", "1:00 PM"],
        //             ["2:00 PM", "2:00 PM"],
        //             ["3:00 PM", "3:00 PM"],
        //             ["4:00 PM", "4:00 PM"],
        //             ["5:00 PM", "5:00 PM"],
        //         ],
        //     }
        // },
        {
            index:1,
            img:require("../assets/images/promociones/background-1.jpg"),
            imgmovil:require("../assets/images/promociones/background-movil-1.jpg"),
            date:{from:'06/15/2023',to:'06/ /2023'},
            name:'Referidos',
        },
    ]
}
export const recorridoVirtual = {
    page:'virtual',
    img:require("../assets/images/recorrido-virtual/recorrido_virtual_desktop.png"),
    imgmovil:require("../assets/images/recorrido-virtual/recorrido_virtual_mobile.png")
}
export const obra = {
    page:'obra',
    img:require("../assets/images/avance_de_obra/background-inicio.jpg")
}
export const departamentos = {
    page:"departamentos",
    img:require("../assets/images/departamentos/departamentos_desktop.png"),
    imgmovil:require("../assets/images/departamentos/departamentos_mobile.png")
}