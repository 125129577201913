import React, { Component } from 'react'
import "./atributo-two.scss"
import $ from "jquery"
import sr from '../../../../assets/scrollreveal/scrollreveal'

$(document).ready(function(){
    sr.reveal(".atributos .items", {origin:"bottom",duration:1800,distance:"120px",delay:200,easing:"ease-out"});
    sr.reveal(".zoom", {origin:"bottom",duration:1800,distance:"120px",delay:200,easing:"ease-out"});
    sr.reveal(".skype", {origin:"bottom",duration:1800,distance:"120px",delay:400,easing:"ease-out"});
});
export default class Atributostwo extends Component {
    constructor(props){
        super(props)
        this.state = {
            icons:[
                {icon:require("../../../../assets/images/Inicio/iconos/ubicacion.svg").default,title:"estratégica",subtitle:"Ubicación"},
                {icon:require("../../../../assets/images/Inicio/iconos/areas-comunes.svg").default,title:"únicas",subtitle:"Áreas comunes "},
                {icon:require("../../../../assets/images/Inicio/iconos/equipamento.svg").default,title:"BOSCH",subtitle:"Equipamiento"},
                {icon:require("../../../../assets/images/Inicio/iconos/mi-vivienda.svg").default,title:"Verde",subtitle:"Mivivienda"},
                {icon:require("../../../../assets/images/Inicio/iconos/gas.svg").default,title:"gas natural",subtitle:"Proyecto con "},
                {icon:require("../../../../assets/images/Inicio/iconos/electrogeno.svg").default,title:"electrógeno",subtitle:"Grupo"},
                {icon:require("../../../../assets/images/Inicio/iconos/ventana.svg").default,title:"reducción de <br/> ruido",subtitle:"Ventanas con"},
                {icon:require("../../../../assets/images/Inicio/iconos/espacio.svg").default,title:"Smart House",subtitle:"Espacio"},
                {icon:require("../../../../assets/images/Inicio/iconos/pet-friendly.svg").default,title:"Petfriendly",subtitle:"Proyecto es"},
            ]
        }
    }
    componentDidMount(){
        document.title="Proyecto Muvin | Departamentos en Lince - Cosapi Inmobiliaria + Gerpal"
        document.querySelector('meta[name="description"]').setAttribute("content", "Muvin es un Proyecto Inmobiliario con departamentos de 2 y 3 dormitorios. Tendremos áreas sociales que cambiarán la forma en la que vives. Con el respaldo de Cosapi Inmobiliaria + Gerpal. ¡Próximo Lanzamiento!");
        document.querySelector('meta[name="keywords"]').setAttribute("content", "Proyecto, inmobiliario, Lince, San Isidro, departamento, departamentos, 2 dorms, 3 dorms, 2 dormitorios, 3 dormitorios, casa propia, Bono Verde, Cosapi Inmobiliaria, Gerpal, MiVivienda, Lanzamiento");
    }
    moveTo(selector,boolean){
        let navbarHeight;
        boolean == true ? navbarHeight = $(".Navbar").height() : navbarHeight = 0;
        if ($(selector).length > 0) {            
            $('html, body').animate({
                scrollTop: $(selector).offset().top - navbarHeight + 1
            }, 700);
        }
               
    }
    render(){
        return (
            <div className="Atributos flex-row" id="atributos">
                <div className="all flex-row">
                    <div className="content-build">
                        <div className='blur-glass'>
                            <img alt="Edicio de muvin inmobiliaria" className="imagen-background desktop"src={require("../../../../assets/images/Inicio/render/fachada-muvin-2023.png")}></img>
                        </div>
                        <img alt="Edicio de muvin inmobiliaria" className="imagen-background"src={require("../../../../assets/images/Inicio/render/fachada-muvin-2023.png")}></img>
                    </div>
                    <div className="Content-atributos flex-column">
                        <img className='background-point-left' src={require('../../../../assets/images/Inicio/iconos/background-points.png')} ></img>
                        <img className='background-point-right' src={require('../../../../assets/images/Inicio/iconos/background-lines-right.png')} ></img>
                        <div className="title">
                            <div className="departamentos c-black">Depas de</div>
                            <div className="rooms c-primary ultra">1, 2 <span className='letterY ultra'>y</span> 3</div>
                            <span className='dorms c-third ultra text-underline '>dormitorios</span>
                            <div className="num-dorms c-black gotham-light">desde <span className="metros c-black ultra">45 m²</span> hasta <span className="metros c-black ultra">70 m²</span> en <span className='ultra'>Lince</span></div>
                            <div className="message c-black gotham-light">POR QUÉ <span className='ultra'>ELEGIR</span> <img src={require('../../../../assets/images/Inicio/iconos/muvin.svg').default} ></img> <span className='ultra'> ?</span></div>
                        </div>
                        <div className="atributos">
                            {
                                this.state.icons.map((item)=>{
                                    return(
                                    <div className="items">
                                        <div className="img-title">
                                            <img src={item.icon}></img>
                                        </div>
                                        <div className="content-title">
                                            <span className='item-subtitle'><span>·</span> {item.subtitle} <span>·</span></span>
                                            <span className='item-title ultra' dangerouslySetInnerHTML={{__html:item.title}}></span>
                                        </div>
                                    </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
