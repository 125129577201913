export const generateId = ({ type })=> {
	const typeid = {
		number: new Date().getTime().toString(),
		string: Math.random().toString(36).substr(2, 18),
	};
	const typeIdDefault = typeid.string;
	return typeid[type] || typeIdDefault;
};
export const animation = (values,classes,reg, space)=>{
    if(values.length > 0){
        let reduce = "";
        let arrayWords = values.split(reg)
        let spaceLetter = ' '
        if(space){
            spaceLetter = ''
        }
        let createSpan = arrayWords.map((e,index)=>{
            reduce += `<span class='${classes} y-hidden'><p class='animate animate-${index}'>${e}</p></span>${spaceLetter}`
        })
        return reduce 
    }
	return ""
}