import { createSlice } from '@reduxjs/toolkit';
const  initialState = {
    value:false,
}
export const noticeStore = createSlice({
    name:"Notice",
    initialState,
    reducers:{
        activeNotice:(state,action)=>{
            state.value = true
        },
        closeNotice:(state,action)=>{
            state.value = false
        }
    }

})
export const { activeNotice,closeNotice } = noticeStore.actions
export default noticeStore.reducer