import React, { Component } from 'react'
import "./contactopro.scss"
import { background } from '../../iconos'
import Formulario from '../../paginas/inicio/formulario/formulario'
import $ from 'jquery'
import { connect } from 'react-redux'
 class Contactopromociones extends Component {
    initCountDown(date,timeBarra,promo) {
        if(document.getElementById("barra")){
            let fin = new Date(date);
            let inicio = new Date(timeBarra);
            let total = fin.getTime() - inicio.getTime();
            let tiempo =  fin.getTime() - new Date().getTime(); 

            let tiempoTotal = Math.round(total/ (1000*60));
            let tiempoRestante = Math.round(tiempo/ (1000*60));

            let widthbarra =  (tiempoRestante / tiempoTotal)*100
            // console.log(widthbarra)
            document.querySelector(`.${promo}`).style = `width:${widthbarra}%`
            
            var end = new Date(date);
            var _second = 1000;
            var _minute = _second * 60;
            var _hour = _minute * 60;
            var _day = _hour * 24;
            var timer;
            var now = new Date();
            var distance = end - now;
            if (distance < 0) {
                clearInterval(timer);
                return;
            }
            var days = String(Math.floor(distance / _day));
            var hours = String(Math.floor((distance % _day) / _hour));
            var minutes = String(Math.floor((distance % _hour) / _minute));
            var seconds = String(Math.floor((distance % _minute) / _second));

            var dias = days.split("");
            var horas = hours.split("");
            var minutos = minutes.split("");
            var segundos = seconds.split("");


            if (dias.length == 1) {
                dias.unshift("0");
            } else {
                dias = dias;
            }
            if (horas.length == 1) {
                horas.unshift("0");
            } else {
                horas = horas;
            }
            if (segundos.length == 1) {
                segundos.unshift("0");
            } else {
                segundos = segundos;
            }
            if (minutos.length == 1) {
                minutos.unshift("0");
            } else {
                minutos = minutos;
            }
            if (minutos.length == 1) {
                minutos.unshift("0");
            } else {
                minutos = minutos;
            }
            if (minutos.length == 1) {
                horas.unshift("0");
            } else {
                dias = dias;
            }
            document.getElementById(`${promo}-dias-first`).textContent = dias[0];
            document.getElementById(`${promo}-dias-second`).textContent = dias[1];
            document.getElementById(`${promo}-horas-first`).textContent = horas[0];
            document.getElementById(`${promo}-horas-second`).textContent = horas[1];
            document.getElementById(`${promo}-minutos-first`).textContent = minutos[0];
            document.getElementById(`${promo}-minutos-second`).textContent = minutos[1];
            document.getElementById(`${promo}-segundo-first`).textContent = segundos[0];
            document.getElementById(`${promo}-segundo-second`).textContent = segundos[1];
        }
    }
    componentDidMount(){
        setInterval(() => {
            // initCountDown(fin de la promocion,inicio de la promocion) orden mes/dia/año
            this.initCountDown('12/31/2021','12/15/2021','promo1') // socios muvin;
            this.initCountDown('01/15/2022','12/16/2021','promo2');
        }, 1000);
        $('html, body').animate({
            scrollTop: $("#inicio").offset().top
        }, 0);
        document.title="Proyecto Muvin | Promociones"
        document.querySelector('meta[name="description"]').setAttribute("content", "Conoce todas nuestras promociones y adquiere un departamento de 2 y 3 dormitorios en Lince a pocos pasos de San Isidro.");
        document.querySelector('meta[name="keywords"]').setAttribute("content", "Proyecto, inmobiliario, Lince, San Isidro, departamento, departamentos, 2 dorms, 3 dorms, 2 dormitorios, 3 dormitorios, casa propia, Bono Verde, Cosapi Inmobiliaria, Gerpal, MiVivienda, Lanzamiento");

    }
    changeDate(){
        $(".date").attr("type","date")
    }
    render() {
        return (
            <section className="contactopro" id="contacto-promociones">
                <div className="background" dangerouslySetInnerHTML={{__html:background}}>
                </div>
                <div className="movil">
                        
                </div>
                <div className="coundDown">
                    <div className="barra promo-1 promo promocion1 active" id="barra">
                        <div class="back promo1"></div>
                    </div>
                    <div className="barra promo-2 promo promocion2" id="barra">
                        <div class="back promo2"></div>
                    </div>
                    <div className="barra promo-3 promo promocion3" id="barra">
                        <div class="back promo3"></div>
                    </div>
                    <span className="time promo-1 promo promocion1 active">La promo acaba en 
                        <span className="days"><span id="promo1-dias-first">0</span><span id="promo1-dias-second">0</span>d: </span>
                        <span className="hours"><span id="promo1-horas-first">0</span><span id="promo1-horas-second">0</span>hr: </span>
                        <span className="minutes"><span id="promo1-minutos-first">0</span><span id="promo1-minutos-second">0</span>m: </span>
                        <span className="seconds"><span id="promo1-segundo-first">0</span><span id="promo1-segundo-second">0</span>s </span>
                    </span>
                    <span className="time promo-2 promo promocion2">La promo acaba en 
                        <span className="days"><span id="promo2-dias-first">0</span><span id="promo2-dias-second">0</span>d: </span>
                        <span className="hours"><span id="promo2-horas-first">0</span><span id="promo2-horas-second">0</span>hr: </span>
                        <span className="minutes"><span id="promo2-minutos-first">0</span><span id="promo2-minutos-second">0</span>m: </span>
                        <span className="seconds"><span id="promo2-segundo-first">0</span><span id="promo2-segundo-second">0</span>s </span>
                    </span>
                    <span className="time promo-3 promo promocion3">La promo acaba en 
                        <span className="days"><span id="promo3-dias-first">0</span><span id="promo3-dias-second">0</span>d: </span>
                        <span className="hours"><span id="promo3-horas-first">0</span><span id="promo3-horas-second">0</span>hr: </span>
                        <span className="minutes"><span id="promo3-minutos-first">0</span><span id="promo3-minutos-second">0</span>m: </span>
                        <span className="seconds"><span id="promo3-segundo-first">0</span><span id="promo3-segundo-second">0</span>s </span>
                    </span>
                </div>
                <Formulario promotion={this.props.value.value}></Formulario>
            </section>
        )
    }
}
const MapStateProps=(state)=>{
    return(
        {
            value:state.promotionStore
        }
    )
}
export default connect(MapStateProps,null)(Contactopromociones)