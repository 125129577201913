import React, { Component } from 'react'
import './brochure.scss'
import OwlCarousel from "react-owl-carousel2"

import $ from "jquery"
const options = {
    smartSpeed:1000,
    singleItem : true,
    responsive:{
        0:{
            items:4,
            margin:10,
        },
        700:{
            items:4
        }

    }
};
const events = {
    onChanged: function(e){

    }
};
export default class Brochure extends Component {
    componentDidMount(){
        $("#area").text("Brochure");
        $(".menu-brochure").addClass("active")
        $(".content-contain").click(function(e){
            console.log(e)
            $("#activePage").text(e.target.dataset.set) 
            $('.flipbook').turn('page',e.target.dataset.catresponsive);
        })
        if(document.querySelector(".content-contain")){
            $("#totalPage").text(document.querySelectorAll(".content-contain").length)
        }
    }
    render() {
        return (
            <div className="brochure">
                <div className="landing-header-obra">
                        <a href="/" className="d-block inicio-brand-muvin"> <img className='' src={require('../../../assets/images/muvin_logo.png')}></img> </a>
                        <a href="https://gerpal.pe/" target="_blank" className="inicio-brand-gerpal"><img src={require('../../../assets/images/GERPAL.png')}></img></a>
                        <a href="https://www.cosapiinmobiliaria.com.pe/" target="_blank"  className="d-block inicio-brand-cosapi"><img src={require('../../../assets/images/COSAPIMORE.png')}></img></a>
                </div>
                <div className="content_brochure">
                    <div className="slider-content">
                    <div class="flipbook-viewport">
                        <div class="container">
                            <div className="flecha flecha-left none"><img src="pages/flecha-left.png"></img></div>
                            <div className="flecha flecha-right"><img src="pages/flecha-right.png"></img></div>
                            <div class="flipbook">
                                <img src="pages/1.jpg"></img>
                                <img src="pages/2.jpg"></img>
                                <img src="pages/3.jpg"></img>
                                <img src="pages/4.jpg"></img>
                                <img src="pages/5.jpg"></img>
                                <img src="pages/6.jpg"></img>
                                <img src="pages/7.jpg"></img>
                                <img src="pages/8.jpg"></img>
                                <img src="pages/9.jpg"></img>
                                <img src="pages/10.jpg"></img>
                                <img src="pages/11.jpg"></img>
                                <img src="pages/12.jpg"></img>
                                <img src="pages/13.jpg"></img>
                                <img src="pages/14.jpg"></img>
                                <img src="pages/15.jpg"></img>
                                <img src="pages/16.jpg"></img>
                                <img src="pages/17.jpg"></img>
                                <img src="pages/18.jpg"></img>
                                <img src="pages/19.jpg"></img>
                                <img src="pages/20.jpg"></img>
                                <img src="pages/21.jpg"></img>
                                <img src="pages/22.jpg"></img>
                                <img src="pages/23.jpg"></img>
                                <img src="pages/24.jpg"></img>
                                <img src="pages/25.jpg"></img>
                                <img src="pages/26.jpg"></img>
                                <img src="pages/27.jpg"></img>
                                <img src="pages/28.jpg"></img>
                                <img src="pages/29.jpg"></img>
                                <img src="pages/30.jpg"></img>
                                <img src="pages/31.jpg"></img>
                                <img src="pages/32.jpg"></img>
                                <img src="pages/33.jpg"></img>
                                <img src="pages/34.jpg"></img>
                                <img src="pages/35.jpg"></img>
                                <img src="pages/36.jpg"></img>
                                <img src="pages/37.jpg"></img>
                                <img src="pages/38.jpg"></img>
                                <img src="pages/39.jpg"></img>
                                <img src="pages/40.jpg"></img>
                                <img src="pages/41.jpg"></img>
                                <img src="pages/42.jpg"></img>
                                <img src="pages/43.jpg"></img>
                                <img src="pages/44.jpg"></img>
                                <img src="pages/45.jpg"></img>
                                <img src="pages/46.jpg"></img>
                                <img src="pages/47.jpg"></img>
                                <img src="pages/48.jpg"></img>
                                <img src="pages/49.jpg"></img>
                                <img src="pages/50.jpg"></img>
                                <img src="pages/51.jpg"></img>
                                <img src="pages/52.jpg"></img>
                                <img src="pages/53.jpg"></img>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="content-controls">
                    <div class="content-toolbar">
                    <OwlCarousel ref="car" options={options} events={events} className="owl-carousel MyCarouselHorizontal owl-theme owl-loaded owl-drag" id="carousel-interiores">
                        <div class="content-contain active" data-index="1">
                            <img class="image active" data-catresponsive="1" data-set="1" src="pages/1.jpg"></img>
                        </div>
                        <div class="content-contain" data-index="2">
                            <img class="image" data-catresponsive="2" data-set="2" src="pages/2.jpg"></img>
                            <img class="image" data-catresponsive="3" data-set="2" src="pages/3.jpg"></img>
                        </div>
                        <div class="content-contain" data-index="4">
                            <img class="image" data-catresponsive="4" data-set="3" src="pages/4.jpg"></img>
                            <img class="image" data-catresponsive="5" data-set="3" src="pages/5.jpg"></img>
                        </div>
                        <div class="content-contain" data-index="6">
                            <img class="image" data-catresponsive="6" data-set="4" src="pages/6.jpg"></img>
                            <img class="image" data-catresponsive="7" data-set="4" src="pages/7.jpg"></img>
                        </div>
                        <div class="content-contain" data-index="8">
                            <img class="image" data-catresponsive="8" data-set="5"src="pages/8.jpg"></img>
                            <img class="image" data-catresponsive="9" data-set="5" src="pages/9.jpg"></img>
                        </div>
                        <div class="content-contain" data-index="10">
                            <img class="image" data-catresponsive="10" data-set="6"src="pages/10.jpg"></img>
                            <img class="image" data-catresponsive="11" data-set="6"src="pages/11.jpg"></img>
                        </div>
                        <div class="content-contain" data-index="12">
                            <img class="image" data-catresponsive="12" data-set="7" src="pages/12.jpg"></img>
                            <img class="image" data-catresponsive="13" data-set="7" src="pages/13.jpg"></img>
                        </div>
                        <div class="content-contain" data-index="14">
                            <img class="image" data-catresponsive="14" data-set="8" src="pages/14.jpg"></img>
                            <img class="image" data-catresponsive="15" data-set="8" src="pages/15.jpg"></img>
                        </div>
                        <div class="content-contain" data-index="16">
                            <img class="image" data-catresponsive="16" data-set="9" src="pages/16.jpg"></img>
                            <img class="image" data-catresponsive="17" data-set="9" src="pages/17.jpg"></img>
                        </div>
                        <div class="content-contain" data-index="18">
                            <img class="image" data-catresponsive="18" data-set="10" src="pages/18.jpg"></img>
                            <img class="image" data-catresponsive="19" data-set="10" src="pages/19.jpg"></img>
                        </div>
                        <div class="content-contain" data-index="20">
                            <img class="image" data-catresponsive="20" data-set="11" src="pages/20.jpg"></img>
                            <img class="image" data-catresponsive="21" data-set="11" src="pages/21.jpg"></img>
                        </div>
                        <div class="content-contain" data-index="22">
                            <img class="image" data-catresponsive="22" data-set="12"src="pages/22.jpg"></img>
                            <img class="image" data-catresponsive="23" data-set="12"src="pages/23.jpg"></img>
                        </div>
                        <div class="content-contain" data-index="24">
                            <img class="image" data-catresponsive="24" data-set="13" src="pages/24.jpg"></img>
                            <img class="image" data-catresponsive="25" data-set="13" src="pages/25.jpg"></img>
                        </div>
                        <div class="content-contain" data-index="26">
                            <img class="image" data-catresponsive="26" data-set="14" src="pages/26.jpg"></img>
                            <img class="image" data-catresponsive="27" data-set="14" src="pages/27.jpg"></img>
                        </div>
                        <div class="content-contain" data-index="28">
                            <img class="image" data-catresponsive="28" data-set="15" src="pages/28.jpg"></img>
                            <img class="image" data-catresponsive="29" data-set="15" src="pages/29.jpg"></img>
                        </div>
                        <div class="content-contain" data-index="30">
                            <img class="image" data-catresponsive="30" data-set="16" src="pages/30.jpg"></img>
                            <img class="image" data-catresponsive="31" data-set="16" src="pages/31.jpg"></img>
                        </div>
                        <div class="content-contain" data-index="32">
                            <img class="image" data-catresponsive="32" data-set="17" src="pages/32.jpg"></img>
                            <img class="image" data-catresponsive="33" data-set="17" src="pages/33.jpg"></img>
                        </div>
                        <div class="content-contain" data-index="34">
                            <img class="image" data-catresponsive="34" data-set="18" src="pages/34.jpg"></img>
                            <img class="image" data-catresponsive="35" data-set="18" src="pages/35.jpg"></img>
                        </div>
                        <div class="content-contain" data-index="36">
                            <img class="image" data-catresponsive="36" data-set="19" src="pages/36.jpg"></img>
                            <img class="image" data-catresponsive="37" data-set="19" src="pages/37.jpg"></img>
                        </div>
                        <div class="content-contain" data-index="38">
                            <img class="image" data-catresponsive="38" data-set="20" src="pages/38.jpg"></img>
                            <img class="image" data-catresponsive="39" data-set="20" src="pages/39.jpg"></img>
                        </div>
                        <div class="content-contain" data-index="40">
                            <img class="image" data-catresponsive="40" data-set="21" src="pages/40.jpg"></img>
                            <img class="image" data-catresponsive="41" data-set="21" src="pages/41.jpg"></img>
                        </div>
                        <div class="content-contain" data-index="42">
                            <img class="image" data-catresponsive="42" data-set="22" src="pages/42.jpg"></img>
                            <img class="image" data-catresponsive="43" data-set="22" src="pages/43.jpg"></img>
                        </div>
                        <div class="content-contain" data-index="44">
                            <img class="image" data-catresponsive="44" data-set="23" src="pages/44.jpg"></img>
                            <img class="image" data-catresponsive="45" data-set="23" src="pages/45.jpg"></img>
                        </div>
                        <div class="content-contain" data-index="46">
                            <img class="image" data-catresponsive="46" data-set="24" src="pages/46.jpg"></img>
                            <img class="image" data-catresponsive="47" data-set="24" src="pages/47.jpg"></img>
                        </div>
                        <div class="content-contain" data-index="48">
                            <img class="image" data-catresponsive="48" data-set="25" src="pages/48.jpg"></img>
                            <img class="image" data-catresponsive="49" data-set="25" src="pages/49.jpg"></img>
                        </div>
                        <div class="content-contain" data-index="50">
                            <img class="image" data-catresponsive="50" data-set="26" src="pages/50.jpg"></img>
                            <img class="image" data-catresponsive="51" data-set="26" src="pages/51.jpg"></img>
                        </div>
                        <div class="content-contain" data-index="52">
                            <img class="image" data-catresponsive="52" data-set="27" src="pages/52.jpg"></img>
                            <img class="image" data-catresponsive="53" data-set="27" src="pages/53.jpg"></img>
                        </div>
                        
                    </OwlCarousel>
                    </div>
                    <div class="content-indicator">
                        <span id="activePage">1</span> / <span id="totalPage">25</span>
                    </div>
                </div>
                    <a target="__blank" href="/brochure-muvin.pdf" className="button-icono">
                        <div className="icono">
                            <img src={require("../../../assets/images/brochure/icono-brochure.png")}></img>
                        </div>
                        <div className="title-button">
                            Descargar Brochure
                        </div>
                    </a>
                </div>
            </div>
        )
    }
}
