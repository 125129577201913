import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import "./assets/scss/index.scss";
import Routes from "./routes";
import $ from "jquery";
import { Provider } from 'react-redux';
import store from './data/store';

window.$ = $
window.jQuery = window.$
window.documentMeta = (tags) => {
    for (const key in tags) {
        if(tags[key] instanceof Object) {
            for(const prop in tags[key]){
                // document.querySelector("meta[description]").remove()
                // document.querySelector("meta[keywords]").remove()

                const meta_tag = document.createElement('meta')
                meta_tag.setAttribute(key, prop)
                meta_tag.setAttribute("content", tags[key][prop])
                document.querySelector('head').appendChild(meta_tag)
            }
        } else {
            const meta_tag = document.createElement('meta')
            meta_tag.setAttribute(key, tags[key])
            document.querySelector('head').appendChild(meta_tag)
        }
    }
}

if(window.location.search){
    localStorage.setItem('url_query', window.location.search)
}

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <Routes/>
        </Provider>
    </React.StrictMode>,
  document.getElementById('root')
);


serviceWorker.unregister();
