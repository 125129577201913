import React, { Component } from 'react'
import "./mouse-scroll.scss"
import $ from 'jquery'
export default class MouseScroll extends Component {
    componentDidMount(){
        
    }
    render() {
        return (
            <div class="scroll-msg-inner">
                <div class="scroll-msg-wheel"/>
            </div>
        )
    }
}
