import React, { Component } from 'react'
import OwlCarousel from "react-owl-carousel2"
import "./Mainpage.scss"
import $, { data } from 'jquery'
import MouseScroll from '../../../mouse-srcoll/mouse-scroll'
import MediaQuery from 'react-responsive';
import { connect } from 'react-redux'
import { changePromotion } from 'data/promotionStore'
import { changeCounter } from 'data/counterStore'
const signal_left = require('../../../../assets/images/Inicio/iconos/signal-left.svg').default;
const signal_right = require('../../../../assets/images/Inicio/iconos/signal-right.svg').default;

class Mainpage extends Component{
    constructor(props){
        super(props)
        this.onDragged = this.onDragged.bind(this)
        this.state = {
            events:{onDragged:function(item){},onChanged:this.onDragged },
            options:{
                items: 1,
                rewind: true,
                loop:false,
                nav:true,
                autoplay:false,
                autoplayTimeout:7000,
                smartSpeed:1000,
                mouseDrag: true,
                touchDrag: true,
                navText:[
                    `<div class="signal-left"><img src='${signal_left}'/></div>`,
                    `<div class="signal-left"><img src='${signal_right}'/></div>`
                ]
            }
        }
        this.car = React.createRef();
    }
    onDragged =(e)=>{
        if(e.page.index >= 0){
            this.props.changeCounter({value:this.props.inicio.img[e.page.index].name,data:this.props.inicio.img[e.page.index]})
        }
        try {
            $(".indicador .item").removeClass("active")
            document.querySelector(`#item-${e.page.index}`).classList.add("active")
        } catch (error) {
            
        }
    }
    componentDidMount(){
        this.props.changeCounter({value:this.props.inicio.img[0].name,data:this.props.inicio.img[0]})
    }
   
    render() {   
        return (
            <div className={"Inicio-two "+ this.props.inicio.page} id="inicio">
                <div className="Inicio-background">
                    <OwlCarousel ref={this.car} options={this.state.options} events={this.state.events} className="owl-carousel MyCarouselHorizontal owl-theme owl-loaded owl-drag">
                        {
                            this.props.inicio.img.map((item)=>{
                                return(
                                    <div className="landing-1">
                                    <MediaQuery query="(min-width: 740px)">
                                        <img className="background1"src={item.img}></img>
                                    </MediaQuery>
                                    <MediaQuery query="(max-width:740px)">
                                        <img className="background1"src={item.imgmovil}></img>
                                    </MediaQuery>
                                    </div>
                                )   
                            })
                        }
                    </OwlCarousel>
                    <div className="signal movil">
                        <div className="flechas flecha-left" onClick={() => this.car.current.prev()}>
                            <div class="signal-left">
                                <img src={signal_left}/>
                            </div>
                        <div className="after"></div></div>
                        <div className="flechas flecha-right"  onClick={() => this.car.current.next()}>
                            <div class="signal-left">
                                <img src={signal_right}/>
                            </div>
                        <div className="after"></div></div>
                    </div>
                    <div className="landing-footer">
                        <div className="items contacto">
                        </div>
                        <div className="items mouse">
                            <MouseScroll></MouseScroll>
                            <div className="more-info">Más info AQUÍ</div>
                        </div>
                        <div className="items counter">
                            <div className="signal">
                                <div className="flechas flecha-left" onClick={() => this.car.current.prev()}><img src={require("../../../../assets/images/iconos/flecha-left.png")}></img><div className="after"></div></div>
                                <div className="flechas flecha-right"  onClick={() => this.car.current.next()}><img src={require("../../../../assets/images/iconos/flecha-right.png")}></img><div className="after"></div></div>
                            </div>
                            <div className="indicador">
                                {
                                    this.props.inicio.img.map((item,index)=>{
                                        return(
                                            <div className={`item ${index == 0 ? "active":""}`} id={`item-${index}`}></div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <CounterStore date={this.props.inicio}></CounterStore>
            </div>
        )
    }
}

class Counter extends Component{
    constructor(props){
        super(props);
        this.state = {
            time:'00 d : 00 hr : 00 m : 00 s',
        }
        console.log('data',props)
    }
    initCountDown =(date,timeBarra,promo)=>{
            let fin = new Date(date);
            let inicio = new Date(timeBarra);
            let total = fin.getTime() - inicio.getTime();
            let tiempo =  fin.getTime() - new Date().getTime(); 

            let tiempoTotal = Math.round(total/ (1000*60));
            let tiempoRestante = Math.round(tiempo/ (1000*60));

            let widthbarra =  (tiempoRestante / tiempoTotal)*100
            // console.log(widthbarra)
            document.querySelector(`.${promo}`).style = `width:${widthbarra}%`
            
            var end = new Date(date);
            var _second = 1000;
            var _minute = _second * 60;
            var _hour = _minute * 60;
            var _day = _hour * 24;
            var timer;
            var now = new Date();
            var distance = end - now;
            if (distance < 0) {
                clearInterval(timer);
                return;
            }
            var days = String(Math.floor(distance / _day));
            var hours = String(Math.floor((distance % _day) / _hour));
            var minutes = String(Math.floor((distance % _hour) / _minute));
            var seconds = String(Math.floor((distance % _minute) / _second));

            var dias = days.split("");
            var horas = hours.split("");
            var minutos = minutes.split("");
            var segundos = seconds.split("");


            if (dias.length == 1) {
                dias.unshift("0");
            } else {
                dias = dias;
            }
            if (horas.length == 1) {
                horas.unshift("0");
            } else {
                horas = horas;
            }
            if (segundos.length == 1) {
                segundos.unshift("0");
            } else {
                segundos = segundos;
            }
            if (minutos.length == 1) {
                minutos.unshift("0");
            } else {
                minutos = minutos;
            }
            if (minutos.length == 1) {
                minutos.unshift("0");
            } else {
                minutos = minutos;
            }
            if (minutos.length == 1) {
                horas.unshift("0");
            } else {
                dias = dias;
            }
            this.setState({time:`${dias[0]}${dias[1]} d : ${horas[0]}${horas[1]}  hr : ${minutos[0]}${minutos[1]} m :  ${segundos[0]}${segundos[1]} s`});
        
    }
    componentDidMount(){
        console.log('props',this.props.value)
        setInterval(() => {
            // Obtener la fecha actual
            const currentDate = new Date();
    
            // Calcular la fecha de inicio del mes actual
            const startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    
            // Calcular la fecha de finalización del mes actual
            const endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    
            // Formatear las fechas en el formato adecuado (MM/DD/YYYY)
            const formattedStartDate = `${startDate.getMonth() + 1}/${startDate.getDate()}/${startDate.getFullYear()}`;
            const formattedEndDate = `${endDate.getMonth() + 1}/${endDate.getDate()}/${endDate.getFullYear()}`;
    
            // Llamar a initCountDown con las fechas calculadas
            this.initCountDown(formattedEndDate, formattedStartDate, 'promo1'); // socios muvin;
        }, 1000);
    }
    render(){
        return(
            <div className='content-count-promo'>
                <div className='content-title'>
                    <span className='border ultra'>Accede a esta promo</span>
                    <span className='ultra text-underline c-white'>antes de que acabe</span>
                </div>
                <div className='content-count'>
                    <span className='ultra c-black title-count'>
                        {this.state.time}
                    </span>
                    <div className='barra promo1'>
                    </div>
                </div>
            </div>
        )
    }
}
const MapStateProps=(state)=>{
    return(
        {
            value:state.counterStore
        }
    )
}

const CounterStore = connect(MapStateProps,{changeCounter})(Counter)



export default connect(null,{changeCounter})(Mainpage)