import React, { Component } from 'react'
import $ from 'jquery'
import './whatsapp.scss'
import Swal from "sweetalert2"
import { LeadService } from 'components/services/LeadService'
import { FormContainer, checkableBoolProps, setInputProps } from 'components/common/forms/Form'
import { BaseValidatonSchema } from 'components/common/forms/constraints/ValidatonSchemas'
export default class Whatsapp extends Component {
    constructor() {
        super()
        this._leadService = new LeadService();
        this.submitForm = this.submitForm.bind(this)
        this.state = {
            show: false
        }
    }
    initialValues = {
        fname: "",
        lname: "",
        email: "",
        phone: "",
        terms: false,
        contact_form: 'whatsapp'
    }
    submitForm(values, { setSubmitting, resetForm }) {
        const form = document.querySelector(".content-contacto-whatsapp")

        // values.seller_id = 100
        this._leadService.send(values)
            .then(r => {
                window.dataLayer && window.dataLayer.push({ 'event': 'LeadWhatsApp' })
                // siempre al cambiar de asesor en link, revisar en Sperant y colocar el seller_id arriba
                // form.querySelector("a.asesores").href=`https://wa.link/dsegtj`
                // form.classList.add("--success")
                window.location = '/gracias'
            }).catch(e => Swal.fire({
                title: '',
                text: '¡Error! Intentelo más tarde',
                icon: 'error',
                confirmButtonText: 'Aceptar',
                confirmButtonColor: 'red'
            }))
    }

    state = {
        wpp: true
    }
    comprobarPosicion = () => {
        const fixedElement = document.querySelector('.svg');
        const footer = document.querySelector('footer');
        if (fixedElement && footer) {
            const fixedElementRect = fixedElement.getBoundingClientRect();
            const footerRect = footer.getBoundingClientRect();
            if (fixedElementRect.bottom >= footerRect.top) {
                fixedElement.classList.add('hidden');
            } else {
                fixedElement.classList.remove('hidden');
            }
        }
    };
    componentDidMount() {
        window.addEventListener('scroll', this.comprobarPosicion);
        $(document).ready(() => {
            var optionsMov = [
                { section: ".formulario", signal: "#svg1", class: "", nav: -200, style: "transition:.2s;transform:scale(0)" },
            ]
            function DetectSecction(options) {
                if (document.querySelector("#svg1") && document.querySelector(".formulario")) {
                    let navbarHeight = document.querySelector("#svg1").getBoundingClientRect().y - 100; //LA ALTURA DEL NAVBAR
                    options.forEach(options => {
                        if ($("#svg1").offset().top > $(".formulario").offset().top) {
                            $("#svg1").attr("transform", "scale(0)")
                            document.querySelector("#svg1").style = "transform:scale(0);transition:0.1s"
                        } else {
                            document.querySelector("#svg1").style = "transform:scale(1);transition:0.1s"
                        }
                    });
                }
            }
            // $(window).scroll(function(){
            //     DetectSecction(optionsMov);
            // });
        })

    }
    whatsapp() {
        this.setState({
            show: !this.state.show
        })
    }
    render() {
        return (
            <div className="items contacto">
                <div className={this.state.show ? "svg wpp active" : "svg wpp"} id="svg1" data-active="false" onClick={this.whatsapp.bind(this)}>
                    <div className="content-svg">
                        <img className='' id="whatsapp" data-active="true" src={require('../../assets/images/iconos/botton-whatsapp.png')}></img>
                    </div>
                </div>
                <div className={this.state.show ? "field active" : "field"}>
                    <FormContainer
                        initialValues={this.initialValues}
                        validationSchema={BaseValidatonSchema}
                        onSubmit={this.submitForm}>
                        {form => {
                            const { handleSubmit } = form;
                            return (
                                <form className="content-contacto-whatsapp " onSubmit={handleSubmit}>
                                    <div className="close" onClick={this.whatsapp.bind(this)}></div>
                                    <div className="content-formulario-contacto ">
                                        <span className="Secondary">Compártenos tus datos y te contactaremos por Whatsapp</span>
                                        <input {...setInputProps("fname", "input-contacto", form)} placeholder="Nombre*"></input>
                                        <input {...setInputProps("lname", "input-contacto", form)} placeholder="Apellidos*"></input>
                                        <input type="number" {...setInputProps("phone", "input-contacto", form)} placeholder="Teléfonos / Celular*"></input>
                                        <input {...setInputProps("email", "input-contacto", form)} placeholder="Correo electrónico*"></input>
                                        <div className="terminos flex-row">
                                            <input type="checkbox"  {...checkableBoolProps("terms", "", form)} id="checkbox"></input>
                                            <label htmlFor="checkbox" className="show-selector"></label>
                                            <p className="condiciones">He leído y acepto la <a href={require("../../assets/files/Politica-de-privacidad.pdf")} target="_blank" className="Primary">política de datos personales.</a></p>
                                        </div>
                                        <button type="submit" class="btn-submit" disabled={form.isSubmitting}>{form.isSubmitting ? "Enviando" : "Enviar"}</button>
                                    </div>
                                    <div className="content-message d-none">
                                        <span className="Secondary text-center d-block title">¡Muchas gracias!</span>
                                        <span className="sub-title">
                                            Un asesor te contactará pronto o puedes escribirle haciendo clic aquí
                                        </span>
                                        <a target="__blank" href="" className="asesores man">
                                            <div className="content-img-asesor hombre">
                                                <img className='' src={require('../../assets/images/iconos/formulario-wpp.svg').default}></img>
                                                {/* <img className='' src={require('../../assets/images/iconos/icono-man.png')}></img> */}
                                            </div>
                                            <a class="hover-link">
                                                <span className="title-asesor">Asesor Diago</span>
                                                <span className="adjectives">Viajero, le gusta bailar y el arroz con pollo</span>
                                            </a>
                                        </a>
                                    </div>
                                </form>
                            )
                        }}
                    </FormContainer>
                </div>
            </div>
        )
    }
}
