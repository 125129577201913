import React, { Component } from 'react'
import Formulario from '../formulario/formulario'
import "./contacto.scss"

export default class SectionContact extends Component {
    render() {
        return (
            <section className='SectionContact'>
                <div className='content-img'>
                    <div className='blur-glass'>
                        <img className='' src={require('../../../../assets/images/Inicio/render/formulario.png')} ></img>
                    </div>
                    <img className='render' src={require('../../../../assets/images/Inicio/render/formulario.png')} ></img>
                </div>
                <div className='content-form'>
                    <img className='background-point-left' src={require('../../../../assets/images/Inicio/iconos/background-points.png')} ></img>
                    <img className='background-point-right' src={require('../../../../assets/images/Inicio/iconos/background-lines-right.png')} ></img>

                    <Formulario></Formulario>
                </div>
            </section>
        )
    }
}
