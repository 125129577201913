import React, { Component } from 'react'
import './slidervirtual.scss'
import MouseScroll from '../../../mouse-srcoll/mouse-scroll'
import $ from 'jquery'
import MediaQuery from 'react-responsive';
import 'lightgallery/dist/css/lightgallery.css';
import 'lightgallery/dist/js/lightgallery';
import 'lightgallery/dist/js/lightgallery-all';

export default class Slidervirtual extends Component {
    constructor(props){
        super(props)
        console.log(props)
    }
    componentDidMount(){
    }
    render() {
        const svg = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48.4 48.4"><defs><style>.cls-1{fill:#fff;}</style></defs><title></title><g id="Capa_2" data-name="Capa 2"><g id="Capa_1-2" data-name="Capa 1"><path class="cls-1" d="M35.31,48.4H35.2a28,28,0,0,1-10.8-2.52,1.89,1.89,0,0,1-1-2.49,1.87,1.87,0,0,1,2.48-1,24.47,24.47,0,0,0,9.33,2.2h.09a11.63,11.63,0,0,0,8.42-3.06v0a3.39,3.39,0,0,0-.17-4.73c-5.07-4.9-7.47-4.54-9.69-2.38l-3.53,3.51a1.9,1.9,0,0,1-2.1.38,38.23,38.23,0,0,1-10.31-7.6l-.25-.25a38,38,0,0,1-7.6-10.31A1.9,1.9,0,0,1,10.47,18L14,14.51c2.16-2.21,2.51-4.61-2.38-9.69a3.42,3.42,0,0,0-4.74-.17v0a11.63,11.63,0,0,0-3.07,8.51c0,6.79,3.89,14.67,10.3,21.1l0,0a44.64,44.64,0,0,0,4.16,3.62,1.88,1.88,0,0,1,.35,2.65,1.9,1.9,0,0,1-2.65.35A48.29,48.29,0,0,1,11.44,37v0C4.31,29.85,0,21,0,13.19A15.32,15.32,0,0,1,4.21,2l.07-.07.06,0a7.18,7.18,0,0,1,10,.36c2.55,2.64,4,4.85,4.63,7a7.81,7.81,0,0,1-2.28,8h0l-2.53,2.56a38.39,38.39,0,0,0,6.23,8.06l.25.25a38.2,38.2,0,0,0,8.06,6.23l2.55-2.54h0c2.41-2.34,5.1-3.11,8-2.27,2.1.61,4.32,2.08,7,4.63a7.2,7.2,0,0,1,.36,10l-.05,0-.07.08A15.33,15.33,0,0,1,35.31,48.4"/><path class="cls-1" d="M36.46,20.7a1.9,1.9,0,0,0,1.28-2.35l0-.09A10.86,10.86,0,0,0,30,10.65a1.89,1.89,0,1,0-1,3.64,6.56,6.56,0,0,1,3.24,1.86,6.84,6.84,0,0,1,1.84,3.18l0,.09a1.9,1.9,0,0,0,1.81,1.36,2,2,0,0,0,.54-.08"/><path class="cls-1" d="M46.4,23.07A20.93,20.93,0,0,0,25.43,2h-.11a1.89,1.89,0,0,0,0,3.78h.1A17.16,17.16,0,0,1,42.62,23.05,1.89,1.89,0,0,0,44.5,25h0a1.89,1.89,0,0,0,1.89-1.88"/></g></g></svg>';
        const muvin = require("../../../../assets/images/Muvin-logo-full-white.png")

        return (
            <div className="sliderVirtual" id="sliderVirtual">
                <div className="content-slider">
                    <div className="sliders">
                    <MediaQuery query="(min-width: 500px)">
                        <img alt="niña viendo realidad virtual"  className="background-virtual" src={this.props.slider.img}></img>
                    </MediaQuery>
                    <MediaQuery query="(max-width:499px)">
                        <img alt="niña viendo realidad virtual"  className="background-virtual" src={this.props.slider.imgmovil}></img>
                    </MediaQuery>
                    </div>
                </div>

                <div className="landing-footer"><div className="items contacto"></div>
                        <div className="items mouse">
                            <MouseScroll></MouseScroll>
                        </div>
                        <div className="items counter">
                        </div>
                </div>
            </div>
        )
    }
}
