import React, { Component } from "react";
import "./obra.scss";
import OwlCarousel from "react-owl-carousel2";
import $ from "jquery";
import "lightgallery/dist/css/lightgallery.css";
import "lightgallery/dist/js/lightgallery";
import "lightgallery/dist/js/lightgallery-all";
const signal_left = require("../../../assets/images/Inicio/iconos/signal-left.svg").default;
const signal_right = require("../../../assets/images/Inicio/iconos/signal-right.svg").default;

const options = {
  items: 1,
  rewind: false,
  loop: false,
  nav: true,
  center: true,
  smartSpeed: 1000,
  navText: [
    `<div class="signal-left"><img src='${signal_left}'/></div>`,
    `<div class="signal-left"><img src='${signal_right}'/></div>`,
  ],
};
const events = {
  onChanged: function (e) {},
};

export default class Obra extends Component {
  constructor(props) {
    super(props);
    this.state = {
      avance: [
        {
          name: "Marzo 2024",
          imagenes: [
            require("../../../assets/images/avance_de_obra/marzo-2024/toma - 1.png"),
            require("../../../assets/images/avance_de_obra/marzo-2024/toma - 2.png"),
            require("../../../assets/images/avance_de_obra/marzo-2024/toma - 3.png"),
          ],
        },
        {
          name: "Febrero 2024",
          imagenes: [
            require("../../../assets/images/avance_de_obra/febrero-2024/img_1.JPG"),
            require("../../../assets/images/avance_de_obra/febrero-2024/img_2.JPG"),
            require("../../../assets/images/avance_de_obra/febrero-2024/img_3.JPG"),
          ],
        },
        {
          name: "Enero 2024",
          imagenes: [
            require("../../../assets/images/avance_de_obra/enero-2024/compress-avance-1.webp"),
            require("../../../assets/images/avance_de_obra/enero-2024/compress-avance-2.webp"),
            require("../../../assets/images/avance_de_obra/enero-2024/compress-avance-3.webp"),
          ],
        },
        {
          name: "Diciembre 2023",
          imagenes: [
            require("../../../assets/images/avance_de_obra/diciembre2023/compress-img1.webp"),
            require("../../../assets/images/avance_de_obra/diciembre2023/compress-img2.webp"),
            require("../../../assets/images/avance_de_obra/diciembre2023/compress-img3.webp"),
          ],
        },
        {
          name: "Noviembre 2023",
          imagenes: [
            require("../../../assets/images/avance_de_obra/noviembre2023/compress-image_1.webp"),
            require("../../../assets/images/avance_de_obra/noviembre2023/compress-img_2.webp"),
            require("../../../assets/images/avance_de_obra/noviembre2023/compress-img_3.webp"),
          ],
        },
        {
          name: "Octubre 2023",
          imagenes: [
            require("../../../assets/images/avance_de_obra/octubre-2023/img-1.JPG"),
            require("../../../assets/images/avance_de_obra/octubre-2023/img-2.JPG"),
            require("../../../assets/images/avance_de_obra/octubre-2023/img-3.JPG"),
          ],
        },
        {
          name: "Septiembre 2023",
          imagenes: [
            require("../../../assets/images/avance_de_obra/septiembre-2023/slider-1.JPG"),
            require("../../../assets/images/avance_de_obra/septiembre-2023/slider-2.JPG"),
            require("../../../assets/images/avance_de_obra/septiembre-2023/slider-3.JPG"),
          ],
        },
        {
          name: "Agosto 2023",
          imagenes: [
            require("../../../assets/images/avance_de_obra/agosto-2023/img-1.JPG"),
            require("../../../assets/images/avance_de_obra/agosto-2023/img-2.JPG"),
            require("../../../assets/images/avance_de_obra/agosto-2023/img-3.JPG"),
          ],
        },
        {
          name: "Julio 2023",
          imagenes: [
            require("../../../assets/images/avance_de_obra/julio-2023/foto-4.png"),
            require("../../../assets/images/avance_de_obra/julio-2023/foto-5.png"),
            require("../../../assets/images/avance_de_obra/julio-2023/foto-7.png"),
            
          ],
        },
        {
          name: "Junio 2023",
          imagenes: [
            require("../../../assets/images/avance_de_obra/junio-2023/avance-de-obra-1.jpg"),
            require("../../../assets/images/avance_de_obra/junio-2023/avance-de-obra-2.jpg"),
            require("../../../assets/images/avance_de_obra/junio-2023/avance-de-obra-3.jpg"),
          ],
        },
        {
          name: "Mayo 2023",
          imagenes: [
            require("../../../assets/images/avance_de_obra/mayo-2023/avance-de-obra-1.jpg"),
            require("../../../assets/images/avance_de_obra/mayo-2023/avance-de-obra-2.jpg"),
            require("../../../assets/images/avance_de_obra/mayo-2023/avance-de-obra-3.jpg"),
          ],
        },
        {
          name: "Abril 2023",
          imagenes: [
            require("../../../assets/images/avance_de_obra/abril-2023/DJI_0362.webp"),
            require("../../../assets/images/avance_de_obra/abril-2023/DJI_0365.webp"),
            require("../../../assets/images/avance_de_obra/abril-2023/DJI_0374.webp"),
          ],
        },
        {
          name: "Marzo 2023",
          imagenes: [
            require("../../../assets/images/avance_de_obra/marzo2023/compress-img-1.webp"),
            require("../../../assets/images/avance_de_obra/marzo2023/compress-img-2.webp"),
            require("../../../assets/images/avance_de_obra/marzo2023/compress-img-3.webp"),
          ],
        },
        {
          name: "Febrero 2023",
          imagenes: [
            require("../../../assets/images/avance_de_obra/febrero-2023/DJI_0255.JPG"),
            require("../../../assets/images/avance_de_obra/febrero-2023/DJI_0261.JPG"),
            require("../../../assets/images/avance_de_obra/febrero-2023/DJI_0268.JPG"),
          ],
        },
        {
          name: "Enero 2023",
          imagenes: [
            require("../../../assets/images/avance_de_obra/enero-2023/img-1.jpg"),
            require("../../../assets/images/avance_de_obra/enero-2023/img-2.jpg"),
          ],
        },
        {
          name: "Diciembre 2022",
          imagenes: [
            require("../../../assets/images/avance_de_obra/diciembre2022/FOTO-1.jpg"),
            require("../../../assets/images/avance_de_obra/diciembre2022/FOTO-2.jpg"),
            require("../../../assets/images/avance_de_obra/diciembre2022/FOTO-3.jpg"),
          ],
        },
        {
          name: "Noviembre 2022",
          imagenes: [
            require("../../../assets/images/avance_de_obra/noviembre2022/compress-img-1.webp"),
            require("../../../assets/images/avance_de_obra/noviembre2022/compress-img-2.webp"),
            require("../../../assets/images/avance_de_obra/noviembre2022/compress-img-3.webp"),
          ],
        },
        {
          name: "Octubre 2022",
          imagenes: [
            require("../../../assets/images/avance_de_obra/octubre/img-1.png"),
            require("../../../assets/images/avance_de_obra/octubre/img-2.png"),
            require("../../../assets/images/avance_de_obra/octubre/img-3.png"),
            require("../../../assets/images/avance_de_obra/octubre/img-4.png"),
          ],
        },
        {
          name: "Setiembre 2022",
          imagenes: [
            require("../../../assets/images/avance_de_obra/setiembre/img-1.jpg"),
            require("../../../assets/images/avance_de_obra/setiembre/img-2.jpg"),
            require("../../../assets/images/avance_de_obra/setiembre/img-3.jpg"),
          ],
        },
        {
          name: "Agosto 2022",
          imagenes: [
            require("../../../assets/images/avance_de_obra/img-1.jpg"),
            require("../../../assets/images/avance_de_obra/img-2.jpg"),
            require("../../../assets/images/avance_de_obra/img-3.jpg"),
          ],
        },
      ],
    };
  }
  componentDidMount() {}
  render() {
    return (
      <div className="avance_obra">
        <img
          className="background-point-left"
          src={require("../../../assets/images/Inicio/iconos/background-points.png")}
        ></img>
        <img
          className="background-point-right"
          src={require("../../../assets/images/Inicio/iconos/background-lines-right.png")}
        ></img>
        <img
          className="line-right-desk"
          src={require("../../../assets/images/Inicio/iconos/lines-right-desk.png")}
        ></img>

        <div className="container">
          {this.state.avance.map((item, index) => {
            return (
              <div
                className={`content-time ${index % 2 == 0 ? "left" : "right"}`}
              >
                <div className="content-separator">
                  <div className="stick">
                    {index == 0 ? <div className="init-ball"></div> : null}
                  </div>
                </div>
                <div className="time">
                  <div class="content-imagen">
                    <OwlCarousel
                      ref="car"
                      options={options}
                      events={events}
                      className="owl-carousel MyCarouselHorizontal owl-theme owl-loaded owl-drag"
                      id="carousel-interiores"
                    >
                      {item.imagenes.map((img) => {
                        return (
                          <img className="imagen" src={img}></img>
                          // <div className='prox'>
                          // Próximamente
                          // </div>
                        );
                      })}
                    </OwlCarousel>
                  </div>
                  <div className="imagen-title">{item.name}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
